// Gantari
@if index($fonts, 'gantari') {
	// @supports not (font-variation-settings: normal) {
		@font-face {
			font-family: "gantari";
			src:
				url("/fonts/gantari-bold.woff2") format("woff2"),
				url("/fonts/gantari-bold.ttf") format("truetype"),
				url("/fonts/gantari-bold.otf") format("opentype");
			font-display: swap;
			font-style: normal;
			font-weight: 700;
		}
	// }
	
	// @supports (font-variation-settings: normal) {
	// 	@font-face {
	// 		font-family:"gantari";
	// 		src: 
	// 			url("/fonts/gantari-variable.ttf") format("truetype supports variations"),
	// 			url("/fonts/gantari-variable.ttf") format("truetype-variations");
	// 		font-display: swap;
	// 		font-weight: 100 1000;
	// 		font-stretch: 25% 151%;
	// 	}
	// }
}