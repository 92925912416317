// Comparison boxes
.comparison-boxes {
	--gutter-x: 0;

	padding: 1rem 0;

	ul {
		display: inline-block;
		li {
			text-align: left;
		}
	}
	
	@include media-breakpoint-up(md) {
		.box {
			min-height: 80%;
			margin: 10% 0;
		}

		> div:first-child {
			padding: 0;
			z-index: 4;

			// Just overlap slightly
			margin: 0 calc(-1 * var(--box-border-radius, $border-radius-sm));

			.box {
				margin: 0;
				height: 100%;
				box-shadow: $box-shadow, $box-shadow-lg; 
			}
		}
	}
}