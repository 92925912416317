// Features accordion
.accordion__heading {
	position: relative;
	-webkit-appearance: unset;

	&:not(.collapsed) {
		pointer-events: none; // Hacky but stops collapsing the only open section
	}

	&.collapsed {
		color: $grey-50;

		&:hover {
			color: $grey;
			text-decoration: none;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

// Little progress bar, not sure if I like it
@include media-breakpoint-up(md) {
	.accordion {
		position: relative;
		padding-left: var(--gutter-x); // Double normal

		&:before {
			top: 0;
			left: calc(0.5 * var(--gutter-x) + 1px);
			content: '';
			height: 100%;
			position: absolute;
			border-left: solid 2px $silver;
		}
	}

	.accordion__heading:not(.collapsed):before {
		width: 5px;
		left: calc(-0.5 * var(--gutter-x));
		content: '';
		height: 100%;
		border-radius: 2px;
		position: absolute;
		background: $navy;
	}
}