// Charity chooser

.search-results__categories {
	.close-icon {
		width: 22px;
		height: 22px;
		float: right;
		border-radius: 50%;
		text-align: center;
		transition: $transition-base;
		background-color: rgba(255, 255, 255, .2);

		&:hover {
			color: $navy;
			background-color: #fff;
		}
	}
}