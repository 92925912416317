#cookie-banner {
	--text-color-rgb: #{to-rgb(#fff)};
	--paragraph-opacity: 1;
	bottom: 0;
	width: 100%;
	color: #fff;
	padding: $grid-gutter-width 0;
	position: fixed;
	z-index: $zindex-tooltip;
	background: rgba($marine, .95);

	@include media-breakpoint-down(md) {
		padding: 20px 0;
		font-size: .875rem;
	}

	a:not(.btn) {
		color: #fff;
		text-decoration: underline;
	}
}
