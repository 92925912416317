/* 
	FAQs
*/

$faqs-border-radius: $btn-border-radius;
$faqs-padding-y: $btn-padding-y-xl;
$faqs-padding-x: $btn-padding-x-xl;

.faqs {
	--faqs-question-color: #{$navy};
	--faqs-question-color-rgb: #{to-rgb($navy)};
	--faqs-question-open-color: #{$navy};
	--faqs-question-bg: #{$white};
	--faqs-question-open-bg: #{$navy-10};
	--faqs-icon-color: #{$gold};
	--faqs-answer-color: #{$navy};
	--faqs-answer-color-rgb: #{to-rgb($navy)};
	--faqs-answer-bg: #{$white};
	--faqs-border-radius: #{$faqs-border-radius};
	position: relative;
	max-width: 50rem;
	margin-left: auto;
	margin-right: auto;
}

// Group = Q+A
.faqs__group {
	border-bottom: var(--faqs-question-border, none);
	margin-bottom: var(--faqs-question-margin, 0.75rem);

	&:last-of-type {
		margin: 0; // Gets rid of bottom margin from last Q so that the height matches the answer block
	}
}

// Set styling of questions
.faqs__question {
	--link-color: var(--faqs-question-color);
	--link-hover-color: var(--faqs-question-open-color);
	width: 100%;
	border: none;
	outline: none;
	display: flex;
	grid-gap: 2rem;
	text-align: left;
	align-items: center;
	padding: $faqs-padding-y $faqs-padding-x;
	transition: $transition-base;
	font-weight: $font-weight-bold;
	color: var(--faqs-question-color);
	background: var(--faqs-question-bg);
	border-radius: var(--faqs-border-radius);

	:first-child {
		flex-grow: 1;
	}

	// Selected/open questions
	&:not(.collapsed) {
		background: var(--faqs-question-open-bg);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	// Link styles (last so they applied to selected and unselected)
	&:hover, &:active {
		text-decoration: none;
		background: var(--faqs-question-hover-bg, var(--faqs-question-open-bg));
	}

	&:focus {
		outline: auto;
	}
}

// Set styling of answers
.faqs__answer  {
	--text-color-rgb: var(--faqs-answer-color-rgb);
	--link-color: var(--faqs-answer-color);
	--link-hover-color: var(--faqs-answer-color);
	color: var(--faqs-answer-color);
	background: var(--faqs-answer-bg); 
	@include border-bottom-radius($faqs-border-radius);

	a:not(.btn) {
		text-decoration: underline;
	}
}

// .faqs__answer can't have padding as it messes with collapse animation (makes it slightly jumpy)
.faqs__answer__text {
	text-align: left;
	padding: $faqs-padding-y $faqs-padding-x;
	margin-bottom: var(--faqs-answer-margin, 0);
}

// This is so that there's always an answer box on the vertical version, plus we use it to mimic 100% height
// If we put 100% height on the actual answer element, it looks funny during the transition animation
.faqs__placeholder {
	display: none;
}

// Plus/minus toggles
.faqs__question {
	.faqs__plus,
	.faqs__minus {
		color: var(--faqs-icon-color);
		display: block;
		line-height: 1;
		font-size: .75rem;
		padding: 0.125rem;
		border-radius: 1rem;
		border: 2px solid var(--faqs-icon-color);
		aspect-ratio: 1; // Reduces CLS when icons loading

		svg {
			aspect-ratio: 1;
		}
	}

	.faqs__plus {
		display: none;
	}
	
	&.collapsed {
		.faqs__plus {
			display: block;
		}
		
		.faqs__minus {
			display: none;
		}
	}
}

//
// Mobile/tablet only
//

@include media-breakpoint-down(lg) {
	// Remove margin if split FAQs and on mobile
	#faqs2 {
		margin-top: -20px;
	}
}

//
// Custom colours
// Uncomment if needed
//

/* 
.faqs--grey {
	--faqs-question-color: #{$white};
	--faqs-question-color-rgb: #{to-rgb($white)};
	--faqs-question-open-color: #{$white};
	--faqs-question-bg: #{$grey};
	--faqs-question-open-bg: #{$grey-70};
	--faqs-icon-color: #{$white};
	--faqs-answer-color: #{$grey};
	--faqs-answer-color-rgb: #{to-rgb($grey)};
	--faqs-answer-bg: #{$grey-10};
}
*/

.faqs--light-navy {
	--faqs-question-bg: #{$navy-5};
	--faqs-answer-bg: #{$navy-5};
}

// .faqs--navy {
// 	--faqs-question-color: #{$white};
// 	--faqs-question-color-rgb: #{to-rgb($white)};
// 	--faqs-question-open-color: #{$white};
// 	--faqs-question-bg: #{$navy};
// 	--faqs-question-open-bg: #{$navy};
// 	--faqs-question-hover-bg: #{$navy-75};
// 	--faqs-icon-color: #{$white};
// 	--faqs-answer-color: #{$navy};
// 	--faqs-answer-color-rgb: #{to-rgb($navy)};
// 	--faqs-answer-bg: #{$navy-10};
// }

/*
.faqs--trans {
	--faqs-question-bg: transparent;
	--faqs-question-open-bg: transparent;
	--faqs-question-hover-bg: #{rgba($navy, 0.05)};
	--faqs-question-border: 1px solid #{rgba($navy, 0.1)};
	--faqs-question-margin: 0;
	--faqs-answer-bg: transparent;
	--faqs-border-radius: 0;
	--faqs-answer-margin: 1.5rem;
}
*/