//
// Header
//

header {
	// Container
	& > .container {
		padding: calc(var(--gutter-x) * .5);
		position: relative;

		@include media-breakpoint-up(md) { 
			padding: 20px calc(var(--gutter-x) * .5);
		}
	}

	// Constrain height of logos
	.brand-logo,
	.charity-logo,
	.corporate-logo {
		width: auto;
		height: 4rem; // Used instead of max-height otherwise col-md-auto can make it disappear!
		transition: $transition-base;

		&:hover {
			opacity: .8;
		}
	}

	// Constrain GAYL logo even further
	.brand-logo {
		height: auto; // Ensures vertical alignment
		max-height: 3.5rem;
	}

	// Make brand logo a bit smaller on mobile
	@include media-breakpoint-down(md) { 
		.brand-logo,
		.charity-logo,
		.corporate-logo {
			max-height: 2.5rem;
		}

		// Text
		b {
			display: block;
			margin-top: 15px;
		}
	}
}