// Oxygen - variable width font so only one needed
@if index($fonts, 'oxygen') {
	@font-face {
		font-family:"oxygen";
		src: 
			url("/fonts/oxygen-regular.woff2") format("woff2"),
			url("/fonts/oxygen-regular.ttf") format("truetype");
		font-display: swap;
		font-style: normal;
		font-weight: 400;
	}

	@font-face {
		font-family:"oxygen";
		src: 
			url("/fonts/oxygen-bold.woff2") format("woff2"),
			url("/fonts/oxygen-bold.ttf") format("truetype");
		font-display: swap;
		font-style: normal;
		font-weight: 700;
	}
}