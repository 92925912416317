/*
	Testimonial stylings
*/

// Featured testimonial - it's full width, can be a carousel if more than 1

.testimonial {
	padding: 0;
	width: 100%;
	height: 100%;
	color: white;
	overflow: hidden;
	position: relative;
}

.testimonial__quotemark {
	top: 0;
	left: -5%;
	width: 35%;
	position: absolute;
}

.testimonial__quotemark-bottom {
	top: unset;
	bottom: 0;
}

.testimonial__img {
	border-radius: 100%;
}
