// This is a copy of Bootstrap's list-group.scss but without all the extra stuff we don't need

.list-group {
  // scss-docs-start list-group-css-vars
  --#{$prefix}list-group-color: #{$list-group-color};
  --#{$prefix}list-group-bg: #{$list-group-bg};
  --#{$prefix}list-group-border-color: #{$list-group-border-color};
  --#{$prefix}list-group-border-width: #{$list-group-border-width};
  --#{$prefix}list-group-border-radius: #{$list-group-border-radius};
  --#{$prefix}list-group-item-padding-x: #{$list-group-item-padding-x};
  --#{$prefix}list-group-item-padding-y: #{$list-group-item-padding-y};
  --#{$prefix}list-group-action-color: #{$list-group-action-color};
  --#{$prefix}list-group-action-hover-color: #{$list-group-action-hover-color};
  --#{$prefix}list-group-action-hover-bg: #{$list-group-hover-bg};
  --#{$prefix}list-group-action-active-color: #{$list-group-action-active-color};
  --#{$prefix}list-group-action-active-bg: #{$list-group-action-active-bg};
  --#{$prefix}list-group-disabled-color: #{$list-group-disabled-color};
  --#{$prefix}list-group-disabled-bg: #{$list-group-disabled-bg};
  --#{$prefix}list-group-active-color: #{$list-group-active-color};
  --#{$prefix}list-group-active-bg: #{$list-group-active-bg};
  --#{$prefix}list-group-active-border-color: #{$list-group-active-border-color};
  // scss-docs-end list-group-css-vars

  display: flex;
  flex-direction: column;

  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;
  @include border-radius(var(--#{$prefix}list-group-border-radius));
}

// Everything else removed except for the loop

// scss-docs-start list-group-modifiers
// List group contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $state, $value in $theme-colors {
  $list-group-variant-bg: shift-color($value, $list-group-item-bg-scale);
  $list-group-variant-color: shift-color($value, $list-group-item-color-scale);
  @if (contrast-ratio($list-group-variant-bg, $list-group-variant-color) < $min-contrast-ratio) {
    $list-group-variant-color: mix($value, color-contrast($list-group-variant-bg), abs($list-group-item-color-scale));
  }

  @include list-group-item-variant($state, $list-group-variant-bg, $list-group-variant-color);
}
// scss-docs-end list-group-modifiers
