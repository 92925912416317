//
// Modals
// 

.modal-close-icon {
	border: 0;
	opacity: 1;
	padding: 0;
	top: .5rem;
	right: .5rem;
	line-height: 1;
	font-size: 2rem;
	position: absolute;
	color: $white;
	transition: color .3s;
	background-color: transparent;
	z-index: $stretched-link-z-index + 1;

	&:hover {
		color: $grey-20 !important;
	}
}

.modal-open {
	& > main, & > header, & > footer, & > section, & > aside, & > .topbar, & > .alert {
		filter: blur(2px);
		overflow: hidden; // Not having this causes an interest bug when the page is 2x wide on mobile when modal open (linked to blurring all the content) 
	}
}

// Add border-radius to modal body if no header
.modal-content > .modal-close-icon ~ .modal-body:nth-child(2) {
	@include border-top-radius($modal-content-border-radius);
}

// Add border-radius to modal body if no footer
.modal-body:last-child,
.modal-content > .list-box {
	overflow: hidden;
	@include border-bottom-radius($modal-content-border-radius);
}

// This was hacky, need to work out where it was being used and putting a better solution in place
// .modal-body > .row {
// 	margin-bottom: -$grid-gutter-width;
// 	& > div {
// 		margin-bottom: $grid-gutter-width;
// 	}
// }

.modal-footer {
	.btn-set {
		width: 100%;
		
		@include media-breakpoint-up(md) {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
}

.modal-table {
	tr {
		& > :first-child {
			padding-left: $grid-gutter-width;
		}
		& > :last-child {
			padding-right: $grid-gutter-width;
		}
	}
}

.modal-body:empty {
	padding: 0;
}

.bg-black pre {
	white-space: pre-wrap; 
	border: 0px;
	background-color: #000;
	color: #bbb;
	font-weight: normal;
	margin: 0px;

	&.text-wrap-pre {
		white-space: pre;
	}
}

// CropperJS overrides
.cropper-outer-container {
	max-width: 100%;
	max-height: 100%;
}

// Make a modal use 100% height - same as .modal-dialog-scrollable but without scrollbars
.modal-cropper {
	height: calc(100% - var(--modal-margin) * 2);

	.modal-content {
		height: 100%; // This is removed by image-upload-crop.js once the cropper has loaded
		max-height: 100%;
	}

	.modal-body {
		overflow: hidden;
	}
}