/**
 *  Hero Sections - Contained version (i.e. not full width)
 *  Some bits are commented out - for ages the blurb and infobox overlapped with the banner image, but marketing said to change
 */

// Infobox

.infobox__logo {
	border-radius: var(--img-border-radius, $border-radius-sm);
}

.infobox__text {
	margin-bottom: 0;

	b {
		color: rgba(var(--text-color-rgb), 1);
	}
}

#infobox__progress {
	z-index: 2;
	display: flex;
	line-height: 1;
	font-size: 2rem;
	aspect-ratio: 1;
	position: relative;
	text-align: center;
	letter-spacing: 2px;
	flex-direction: column;
	margin: var(--box-padding-y, 1.5rem) auto;

	b {
		left: 0;
		right: 0;
		top: 50%;
		color: var(--green);
		text-align: center;
		position: absolute;
		transform: translateY(-50%);
		transition: $transition-base;
		font-family: var(--font-headings);
	}

	canvas {
		margin: auto;
		max-width: 100%;
		max-height: 100%;
		border-radius: 50%;
		transition: $transition-base;
	}

	&.complete {
		b {
			color: #fff;
		}

		canvas {
			box-shadow: 0 0 2rem var(--green);
			animation: spin 3000ms infinite linear; 
			background: radial-gradient(circle at top right, var(--green-50), var(--green));
		}
	}
}

// Spin the circle-progress thing round
@keyframes spin {
	from { transform: rotate(  0deg) }
	to   { transform: rotate(360deg) }
}