// 
// Styles for Wagathon campaign
//

// Variables

$wagathon-pink:   #e72869;
$wagathon-blue:   #67c4ff;
$wagathon-yellow: #ffc700;
$wagathon-green:  #40b718;
$wagathon-purple: #6a43c9;
$wagathon-orange: #ff8500;
$wagathon-indigo: #0055ff;

// Fonts

@import "~Sass/common/fonts-zufo";

.text-zufo {
	font-weight: 500;
	letter-spacing: .2rem;
	text-transform: uppercase;
	font-family: zufo-regular, 'Arial Black', sans-serif !important;
}

// Backgrounds

.bg-wagathon-pink {
	background: $wagathon-pink;
}
.bg-wagathon-blue {
	background: $wagathon-blue;
}
.bg-wagathon-yellow {
	background: $wagathon-yellow;
}
.bg-wagathon-green {
	background: $wagathon-green;
}
.bg-wagathon-purple {
	background: $wagathon-purple;
}
.bg-wagathon-orange {
	background: $wagathon-orange;
}
.bg-wagathon-indigo {
	background: $wagathon-indigo;
}

// Buttons

.btn-wagathon-green {
	@include button-variant($wagathon-green, $wagathon-green);
}

.btn-wagathon-orange {
	@include button-variant($wagathon-orange, $wagathon-orange);
}

.btn-wagathon-indigo {
	@include button-variant($wagathon-indigo, $wagathon-indigo);
}

// .btn-outline-wagathon-indigo {
// 	@include button-outline-variant($wagathon-indigo);
// }

// Text (this is also used by the wavy dividers)

.text-wagathon-pink {
	color: $wagathon-pink;
}
.text-wagathon-blue {
	color: $wagathon-blue;
}
.text-wagathon-yellow {
	color: $wagathon-yellow;
}
.text-wagathon-green {
	color: $wagathon-green;
}
.text-wagathon-purple {
	color: $wagathon-purple;
}
.text-wagathon-orange {
	color: $wagathon-orange;
}
.text-wagathon-indigo {
	color: $wagathon-indigo;
}

// Brushstrokes
.bg-brush-stroke-container { 
	position: relative;
	text-align: center;
	
	img {
		z-index: 2;
		position: relative;
	}
}

.bg-brush-stroke {
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	-webkit-mask-image: url('/images/common/swoosh/brush-1.svg');
	mask-image: url('/images/common/swoosh/brush-1.svg');
    -webkit-mask-size: 100% 100%;
	mask-size: 100% 100%;
}
