//
// Forms
//

form {
	h6, h5, h4, h3 {
		margin-bottom: $form-label-margin-bottom;
	}
}

// Readonly fields - make them appear like disabled ones. Don't use :read-only
.form-control[readonly] {
	color: $input-disabled-color;
	background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
}

// Removed from Bootstrap 5
.form-group {
	margin-bottom: 1rem;
}

// Label info
label .text-sm {
	font-weight: normal;
}

// Inline helper text next to labels messes with margins
label:has(+ span.text-sm) {
	margin-bottom: 0;

	+ span:after {
		content: '';
		display: block;
		height: $form-label-margin-bottom;
	}
}

// Custom label sizes
.form-label-lg { font-size: 1.125rem; } // 18px
.form-label-xl { font-size: 1.375rem; } // 22px

.form-label-spacer {
	width: 100%;
	height: 1lh;
}

// Add margin for paired checkboxes
.form-check + .form-check {
	padding-top: .5rem;
}
.form-group:has(> .form-check:first-child) {
	margin-top: -.5rem;

	.form-check {
		padding-top: .5rem;
	}
}

// Make gap between checkbox and label clickable
.form-check {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&.form-check-inline {
		display: inline-flex;

		&:has(~ .form-check-inline) {
			margin-right: 3 * $form-check-inline-margin-end;
		}
	}

	> * {
		flex-shrink: 0;
	}

	// Whilst the below two could be combined into one, we want padding for label so that the gap is clickable
	.form-check-input + .form-check-label {
		width: 100%; // Fixes weird flex-bug where it breaks onto new line
		padding-left: $form-check-gap;
	}

	// Any extra elements could be <a>, extra padding would cause issues with link-block-* styles
	.form-check-label ~ * {
		max-width: 100%;
		margin-left: $form-check-gap;
	}
}

// Make radios act like checkboxes - see _form-check.scss
.form-check-input {
	&[type="radio"] {
		@include border-radius($form-check-input-border-radius);
	}

	&:checked {
		&[type="radio"] {
			@if $enable-gradients {
				background-image: escape-svg($form-check-input-checked-bg-image), var(--gradient);
			} @else {
				background-image: escape-svg($form-check-input-checked-bg-image);
			}
		}
	}
}

// Pin number
.form-pin {
	$pin-spacing: 1ch;

	max-width: 14ch; // Must account for error icon
	letter-spacing: $pin-spacing;
	font-family: var(--font-monospace);
}

.form-pin-group {
	display: flex;
	gap: 0.5rem;
}

.form-pin-single {
	width: calc(2ch + (2 * $input-btn-padding-x-lg));
	text-align: center;
	font-family: var(--font-monospace);

	// The below remove the up/down arrows
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
  		margin: 0;
	}
}

// Pin number
.form-discount-code {
	text-transform: uppercase;
	font-family: var(--font-monospace);
}

// Stops labels and checkboxes going onto one line
.form-label:has(+ .form-check),
.form-label:has(+ .form-check-inline) {
	display: block;
}

// Button radio
.btn-radio {
	position: relative;

	.form-check {
		margin: 0;
		color: inherit;
	}

	.form-check-label {
		// Copied from Bootstrap's helpers/_stretched-link.scss
		&::#{$stretched-link-pseudo-element} {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: $stretched-link-z-index;
			content: "";
			// Added by us
			cursor: pointer;
		}
	}
}

// Grey out select based on value
select:has(option:checked[value='']),
select:has(option:checked[value='0']),
select:has(option:checked[value='-1']) {
	color: $input-placeholder-color;
}

// Safari date input fix (weird padding added)
input::-webkit-datetime-edit {
	display: block;
	padding: 0;
}

.js-email-field {
	border-width: 1px;
}

.text-red[class*=error_] {
	display: block;
	margin: .5rem 0 0 0;
}

// Improvements to alerts (why isn't :empty part of Bootstrap by default?)
.alert[class*=error_], .alert[id*=error_] {
	margin-top: .5rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.alert:empty {
	display: none;
}

input, textarea, select, .input-group, .form-control {
	+ .alert:not(:empty) {
		margin: .5rem 0 0 0;
	}
}

// 
// Small checkboxes 
//

@if $stylesheet == 'giftcards' or $stylesheet == 'giveasyoulive' {
	.form-check-label-sm {
		font-size: .875rem;
		
		&::before, 
		&::after {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

//
// Search+button combo - detaches on mobile
//

$searchDetachBreakpoint: lg;

@if $stylesheet == 'check-in' {
	$searchDetachBreakpoint: md;
}

.search-detach__form { // Copy of .input-group
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	max-width: 700px; // New, not from .input-group
}

.search-detach__input {
	@include media-breakpoint-up($searchDetachBreakpoint) {
		width: 1%;
		margin-bottom: 0;
		position: relative;
		flex: 1 1 auto;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.search-detach__button {
	display: flex;

	@include media-breakpoint-down($searchDetachBreakpoint) {
		width: 100%;
		margin-top: 1rem;
	}
	.btn {
		margin: 0 !important; // !important needed for when it's in .hero
		width: 100%;
		@include media-breakpoint-up($searchDetachBreakpoint) {
			width: auto;
			margin: 0 0 0 -1px !important; // !important needed for when it's in .hero
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

// Drag and drop file input (HTML requires fa-cloud-upload-alt)
.dragdrop-container {
	width: 100%;
	display: flex;
}

.dragdrop-file {
	width: 100%;
	color: $navy;
	padding: 1rem;
	display: block;
	position: relative;
	text-align: center;
	background: $navy-10;
	align-content: center;
	border: 2px dashed $navy;
	border-radius: $border-radius;
}

.dragdrop-file-input {
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.dragdrop-file-label {
	display: block;
	cursor: pointer;
	margin-top: 1rem;
	margin-bottom: 0;

	+ span {
		display: block;
		padding-top: 0.5rem;
		--text-opacity: var(--paragraph-opacity, #{$paragraph-text-opacity});
		color: rgba(var(--text-color-rgb), var(--text-opacity));
	}
}

.dragdrop-file-hover {
	color: $green;
	border-color: $green;
	background: $green-10;
}

// Password toggle
.password-container {
	position: relative;

	.btn {
		top: 0;
		right: 0;
		position: absolute;
		margin: 0 !important; // Urgh
		transform: translateY(calc(-100% - .5rem));
	}
}

// Stack input group (!important flags needed unfortunately)
@include media-breakpoint-down(lg) {
	.input-group-stack {
		.input-group-text {
			width: 100%;
			margin-bottom: -1px;
			justify-content: center;
			border-radius: $input-border-radius $input-border-radius 0 0 !important;

			&:last-child {
				border-radius: 0 0 $input-border-radius $input-border-radius !important;
			}
		}

		.form-control {
			margin-left: 0 !important;
			border-radius: 0 0 $input-border-radius $input-border-radius !important;

			&:first-child {
				border-radius: 0 0 $input-border-radius $input-border-radius !important;
			}
		}
	}
}

// Buttons in input-groups
button.input-group-text:not(.btn) {
	align-items: center;
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: math.div($btn-padding-x, 2);
}

.input-group-separate {
	gap: 0.5rem;
	display: flex;
	flex-wrap: wrap;

	> * {
		flex-grow: 1;
	}

	@include media-breakpoint-up(md) {
		.btn {
			flex-grow: 0;
		}

		> input, 
		> select {
			width: auto;	
		}
	}
}

// Stripe overrides for better vertical alignment
.form-control.StripeElement {
	display: flex;
	align-items: center;
	flex-direction: row;
	height: calc(1.5em + (2 * $input-padding-y) + (2 * $input-border-width));

	> .__PrivateStripeElement {
		flex-grow: 1;
	}
}