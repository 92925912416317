// Import vanilla select2 styles
@import "~Vendor/select2/src/scss/core";

// The below now overrides certain bits to mimic Bootstrap 5 styles
// Previously tried a plugin but didn't work well out the box
// In the JS, it has a theme of "bootstrap" specified - technically we're supposed to wrap all of the below in .select2-container--bootstrap but it doesn't make a difference to the final CSS

// The container
.select2-container {
	// Styles that affect both single and multiple
	.select2-selection {
		background: $form-select-bg;
		line-height: $form-select-line-height;
		padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
		border: $form-select-border-width solid $form-select-border-color;
	}

	// Single selection
	.select2-selection--single {
		height: unset;

		.select2-selection__rendered {
			padding: 0;
		}		
	}

	// Multiple selection
	.select2-selection--multiple {
		.select2-selection__rendered {
			display: block;
			margin-bottom: 0.25rem; // The selected results have a bottom margin so gap is really 0.5rem

			&:empty {
				margin-bottom: 0;
			}
		}
	}

	// Multiple selection
	.select2-search--inline {
		.select2-search__field {
			margin: 0;
			padding: 0;
			height: 1.5em; // EM as relative to parent not root
			font-family: unset;
			color: $form-select-color;

			&:focus {
				outline: 0;
			}
		}
	}
}

.select2-selection {
	border-radius: $form-select-border-radius;
}

.select2-container--open {
	&.select2-container--above {
		.select2-selection {
			border-top-width: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&.select2-container--below {
		.select2-selection {
			border-bottom-width: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

// The initially visible input
.select2-selection--single {
	.select2-selection__placeholder {
		color: $input-placeholder-color;
	}

	.select2-selection__arrow {
		background-image: escape-svg($form-select-indicator);
		background-repeat: no-repeat;
		background-position: $form-select-bg-position;
		background-size: $form-select-bg-size;

		top: 0;
		right: 1px;
		height: 100%;
		position: absolute;
		width: $form-select-indicator-padding;
	}
}

// The dropdown container
.select2-dropdown {
	overflow: hidden;
	border: $form-select-border-width solid $form-select-border-color;
	@include border-radius($form-select-border-radius, 0);
}

// The search box that appears in the dropdown (single selection)
.select2-search--dropdown {
	padding: $form-select-focus-width * 2;

	.select2-search__field  {
		color: currentColor;
		padding: $input-padding-y $input-padding-x;
		border: $form-select-border-width solid $form-select-border-color;
		@include border-radius($form-select-border-radius, 0);

		// The glow when focused
		&:focus {
			outline: 0;
			border-color: $form-select-focus-border-color;

			// Copied from _form-select.scss
			@if $enable-shadows {
				@include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
			} 
			@else {
				box-shadow: $form-select-focus-box-shadow;
			}
		}
	}
}

// Results list
.select2-results__options {
    max-height: 19.25rem;
    overflow: auto;
}

// A result
.select2-results__option {
	padding: $form-select-padding-y $form-select-padding-x;

	&--highlighted {
		color: $white;
		background-color: $navy;
	}
}

// Selected multi-result
.select2-selection__choice {
	display: inline-block;
	padding: 0.125rem 0.5rem;
	margin: 0 .25rem .25rem 0;
	color: $form-select-color;
	border-radius: $input-border-radius-sm;
	border: $form-select-border-width solid $form-select-border-color;
	@include font-size($form-select-font-size-sm);
}

// Remove multi-result
.select2-selection__choice__remove {
	padding: 0;
	border: none;
	background: none;
	color: $form-select-color;
	margin-right: 0.25rem;
}

// Remove single-result
.select2-selection__clear {
	float: right;
}