// Import the Tom Select styles (the Bootstrap5 version wil automatically use the Bootstrap variables (inc our overrides), but if anything else needs to change then add before the import)
$select-padding-dropdown-item-y: $input-btn-padding-y;
$select-color-dropdown-border-top: color.mix($input-border-color, $input-bg, 80%); // Due to depreciation warning
$select-color-text: $form-select-color;

@import "~Vendor/tom-select/dist/scss/tom-select.bootstrap5";

// Fix the input not squaring off when the dropdown shows 
.ts-wrapper {	
	&.dropdown-active,
	&.dropdown-active .ts-control {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

// Fix the dropdown top corners not being square, weird margin, and remove overflow to stop bottom border-radius breaking
.ts-dropdown {
	margin: 0;
	overflow: hidden;
	border-top-left-radius: 0;
	border-top-right-radius: 0;	
}

// Remove pointless padding after last dropdown item
.ts-dropdown-content {
	padding: 0;
}

// Hide the selection when you re-focus the input (otherwise the new text goes after the selected text and looks odd)
.single .ts-control:has(input[aria-expanded="true"]) {
	.item {
		display: none;
	}
}

.ts-control > input:not(:focus)::placeholder {
	color: $input-placeholder-color;
}

// Stop text overlapping with expand arrow
.ts-control > input {
	max-width: calc(100% - ($input-padding-x + 1.25rem)) !important;
}