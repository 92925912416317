// Featured Retailers/Charities 50:50 Grid

.featured-grid {
	padding-top: 30px;
	padding-bottom: 30px;
}

.featured-grid--items a {
	width: 21%; // This is here as a fail-safe in case nth-child not supported
	margin: 2%;
	float: left;
	height: auto;
	display: none; // This is because we dynamically choose how many to display below
	transition: $transition-base;

	&:nth-child(-n+6) { // Show 6 items, 3 per line
		width: 29%;
		display: inline-block;
	}

	@include media-breakpoint-up(md) {
		&:nth-child(-n+12) { // Show 12 items, 3 per line
			width: 29%;
			display: inline-block;
		}
	}

	@include media-breakpoint-up(lg) {
		&:nth-child(-n+16) { // Show 16 items, 4 per line
			width: 21%;
			display: inline-block;
		}
	}

	&:hover {
		transform: scale(1.1);
	}
}