// Temporarily overriding $theme-colors allows us to only build components for specific colours
// $theme-colors: filter-master-colors($colour-list-buttons);

// Now we can use Bootstrap's partial
@import "~BootstrapSass/buttons";

// Reset $theme-colours
// $theme-colors: ();

// Tweaks to the Bootstrap mixin:
// - Chaned outline button hover styles to use off-white background rather inverting the colours
// - Also added extra options to the special light/dark variant so that it gets a better hover state
// The $theme-color changes are commented out above as we want to use these loops rather than Bootstrap's

@each $color, $value in filter-master-colors-adj($colour-list-buttons, 75) {
  // Hack to get adjusted contrast ratio to pass through mixins (but not used after this file)
  $original-min-contrast-ratio: $min-contrast-ratio;
  $min-contrast-ratio: $btn-contrast-ratio;

  .btn-#{$color} {
    @if $color == "light" or $color == "white" or $color == "silver" or $color == "email" {
      @include button-variant(
        $value,
        $value,
        $color: $link-color,
        $hover-color: $link-color,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount)
      );
    } @else if $color == "dark" or $color == "black" or $color == "grey" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-tint-amount),
        $active-background: tint-color($value, $btn-active-bg-tint-amount),
        $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    } @else {
      @include button-variant($value, $value);
    }
  }

  // And change back to original value
  $min-contrast-ratio: $original-min-contrast-ratio;
}

/* Override Bootstrap mixin - added $border input variable and changed --btn-border-color to use value */
@mixin button-outline-variant-override(
  $color,
  $border: $color, 
  $hover-color: color-contrast($color),
  $hover-border: $border,
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$color};
  --#{$prefix}gradient: none;
}

@if $enable-outline-buttons {
  // Hack to get adjusted contrast ratio to pass through mixins (but not used after this file)
  $original-min-contrast-ratio: $min-contrast-ratio;
  $min-contrast-ratio: $btn-contrast-ratio;

  @each $color, $value in filter-master-colors($colour-list-buttons) {
    // Hack to use different border colour
    $btn-border-colour: map.get($colour-map-master, "#{$color}-25");
    $btn-border-hover-colour: map.get($colour-map-master, "#{$color}-75");

    @if $btn-border-colour == null {
      $btn-border-colour: mix(#fff, $value, 25%);
    }

    @if $btn-border-hover-colour == null {
      $btn-border-hover-colour: mix(#fff, $value, 75%);
    }

    .btn-outline-#{$color}#{if($color == 'navy', ', .btn-default', '')} {
      @include button-outline-variant-override(
      	$color: $value,
        $border: $btn-border-colour,
      	$hover-color: if(contrast-ratio(#fff, $value) > $btn-contrast-ratio, $value, color-contrast($value)),
        $hover-border: $btn-border-hover-colour,
      	$active-background: if(contrast-ratio(#fff, $value) > $btn-contrast-ratio, mix(color-contrast($value), $value, 90%), mix(color-contrast($value), $value, 10%)),
        $active-border: $btn-border-colour
      );
    }
  }

  // And change back to original value
  $min-contrast-ratio: $original-min-contrast-ratio;
}
