@import "~Sass/common/countdown";

//
// Featured events (copied from featured offers)
//

.featured-events {
	// Makes it line up vertically
	.row > div {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	// The header - we use the :before trick to make the <img> banner a background-image so we can clip it;
	.event__header {
		width: 100%;
		overflow: hidden;
		position: relative;
		background: $blue;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		background: linear-gradient(to right, $brand 0%, tint-color($brand, 10%) 100%);
		&:before {
			content: "";
			display: block;
			padding-top: 26%;
		}
	}

	// Banner image
	.event__banner {
		top: 0;
		width: 100%;
		min-height: 100%;
		object-fit: cover;
		position: absolute;
	}

	// Logo
	.event__logo {
		position: absolute;
		top: 10%;
		left: 20px;
		height: 80%;
		width: auto !important; // Otherwise it doesn't take effect
	}

	// Button
	.event__button {
		width: 45%;
		right: 20px;
		bottom: 10%;  
		position: absolute;
	}
}