/* 
 * Common table styles
 */

.table-collapse {
	@include media-breakpoint-down(md) {
		th, td {
			border: none;
			display: block;
			width: 100% !important;
			white-space: unset !important;
		}
		th:empty {
			display: none;
		}
		td {
			padding: math.div($table-cell-padding-y, 2) math.div($table-cell-padding-x, 2);

			&:first-child {
				border-top: $table-border-width solid $table-border-color;
				padding-top: $table-cell-padding-y;
			}
			&:last-child {
				padding-bottom: $table-cell-padding-y;
			}
		}
	}
}

// Sticky header
.table-sticky {
	position: relative;
	thead th {
		top: -1px; // Should be 0 but noticed some leakage on Chrome
		position: sticky;
	}
}

// Make cells inherit table alignment
.table[class*='align-'] {
	td, th {
		vertical-align: inherit;
	}
}

// In a content box
.box__table {
	margin-bottom: 0;
	thead th, tfoot th {
		background: $navy-10;
	}
	tr > :first-child {
		padding-left: $box-padding-x;
	}
	tr > :last-child {
		padding-right: $box-padding-x;	
	}
	th, td {
		vertical-align: middle;
	}
	thead th {
		vertical-align: middle;
	}
}

// Collapse with padding
.box__table.table-collapse {
	@include media-breakpoint-down(md) {
		td, th {
			padding-left: $box-padding-x;
			padding-right: $box-padding-x;
		}
	}
}

// Make full width in a content box (bit hacky...)
.box__content .box__table {
	margin-left: -$box-padding-x;
	margin-right: -$box-padding-x;
	width: calc(100% + ($box-padding-x * 2));
	max-width: calc(100% + ($box-padding-x * 2));
}

.th-nowrap th {
	white-space: nowrap !important;
}

// Improve table row hiding
tr.collapse:not(.show) {
	display: table-row;
	visibility: collapse;
}

.green-pulse {
	background-color: rgba(120, 200, 120, 0.3);
	animation-name: green-pulse-color;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

@keyframes green-pulse-color {
	0% {
		background-color: rgba(120, 200, 120, 0.3);
	}
	50% {
		background-color: rgba(120, 200, 120, 0.8);
	}
	99% {
		background-color: rgba(120, 200, 120, 0.3);
	}
}

th .text-sm {
	font-weight: $font-weight-normal;
}

// Fix for Firefox invisible borders (important needed as short-hand background will override it)
body.browser--firefox td {
	background-clip: padding-box !important;
}
