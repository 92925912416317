// Don't bother with the 'bs' prefix - use --foo rather than --bs-foo
// If we add this back, any use of Bootstrap variables in SCSS (and a couple in JS) will need to be checked and updated
$prefix: '';

// Theme colours and utilities
$table-variants: filter-master-colors($colour-list-tables);
$utilities-bg: filter-master-colors($colour-list-backgrounds);
$utilities-border: filter-master-colors($colour-list-borders);
$utilities-text: filter-master-colors($colour-list-text);

// Import fonts
$fonts: ('oxygen', 'gantari') !default; // If overriding, also set the fonts in the template VM so that it preloads
@import "~Sass/common/fonts-oxygen";
@import "~Sass/common/fonts-gantari";

// Fonts (default is copied from Bootstrap)
$default-font-family:    system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: oxygen, #{$default-font-family};
$headings-font-family:   gantari, #{$default-font-family};
$display-font-family:    gantari, #{$default-font-family};
$btn-font-family:        gantari, #{$default-font-family};

$font-weight-light:      300;
$font-weight-normal:     500;
$font-weight-bold:       700;
$font-weight-bolder:     700;
$headings-font-weight:   700;
$headings-margin-bottom: 1rem; // Bootstrap default: $spacer * .5 !default; // CHECK
$display-font-sizes:     ();   // Stops .display-* classes 
$font-sizes:             ();   // Stops .fs-* classes
$link-decoration:        none;
$link-hover-decoration:  underline;
$paragraph-text-opacity: 0.8; // Our variable. Make standard text slightly transparent

// Colour contrast
$min-contrast-ratio: 2; // 4.5 default but causes some buttons to have dark text. Blue = 2.36. Not ideal to bring down this far though.
$btn-contrast-ratio: 1.7; // Used for choosing hover styles (as 5% of silver is white, so want to invert)

// Gutters - we have to increase this because various banners are sized to 730px and a col-8 is now 736px due to the decreased gutter size
$grid-gutter-width: 2rem;

// Border Radius
$border-radius-xs:  0.25rem;
$border-radius-sm:  0.375rem;
$border-radius:     0.5rem;
$border-radius-lg:  0.625rem;
$border-radius-xl:  0.75rem;
$border-radius-xxl: 0.875rem;
$border-radius-2xl: 1rem;

// Modals
$modal-content-border-radius:  .5rem;
$modal-header-padding-x:      1.5rem;
$modal-header-padding-y:      1rem;
$modal-header-padding:        $modal-header-padding-y $modal-header-padding-x;
$modal-inner-padding:         1.5rem;

// Inputs
$component-active-bg: $navy;
$input-border-color: #ccc;
$input-group-addon-bg: $silver;
$input-group-addon-border-color: #ccc;

// Labels
$form-label-font-weight: $font-weight-bold;

// Inputs - checkboxes
$form-check-input-bg: transparent;
$form-check-padding-start: 1.5rem; // Same as input width - gap between is added in form.scss
$form-check-input-width: 1.5rem;
$form-check-input-height: 1.5rem;
$form-check-input-border-radius: 50%;
$form-check-input-border: 3px solid $navy-75;
$form-check-input-checked-bg-color: $navy-75; // As buttons use 75%
$form-check-gap: 1rem; // Our own variable

// Inputs / Buttons - vertical padding
$input-btn-padding-y-xs:  .125rem;  //  2px
$input-btn-padding-y-sm:  .25rem;   //  4px - copied from Bootstrap
$input-btn-padding-y:     .375rem;  //  6px - copied from Bootstrap
$input-btn-padding-y-lg:  .5rem;    //  8px - copied from Bootstrap
$input-btn-padding-y-xl:  .625rem;  // 10px
$input-btn-padding-y-xxl: .75rem;   // 12px

// Inputs / Buttons - horizontal padding
$input-btn-padding-x-xs:    .25rem; //  4px -> 6px
$input-btn-padding-x-sm:    .5rem;  //  8px -> 12px
$input-btn-padding-x:       .75rem; // 12px -> 18px
$input-btn-padding-x-lg:   1rem;    // 16px -> 24px
$input-btn-padding-x-xl:   1.25rem; // 20px -> 30px
$input-btn-padding-x-xxl:  1.5rem;  // 24px -> 36px

// Buttons - vertical padding (xs/xl/xxl are new sizes)
$btn-padding-y-xs:  $input-btn-padding-y-xs;
$btn-padding-y-sm:  $input-btn-padding-y-sm;
$btn-padding-y:     $input-btn-padding-y;
$btn-padding-y-lg:  $input-btn-padding-y-lg;
$btn-padding-y-xl:  $input-btn-padding-y-xl;
$btn-padding-y-xxl: $input-btn-padding-y-xxl;

// Buttons - horizontal padding (xs/xl/xxl are new sizes, border added to all to keep horizontal padding double vertical padding)
$btn-padding-x-xs:  $input-btn-padding-x-xs;
$btn-padding-x-sm:  $input-btn-padding-x-sm;
$btn-padding-x:     $input-btn-padding-x;
$btn-padding-x-lg:  $input-btn-padding-x-lg;
$btn-padding-x-xl:  $input-btn-padding-x-xl;
$btn-padding-x-xxl: $input-btn-padding-x-xxl;

// Buttons fonts - sm is 0.875rem (14px), md is 1rem (16px), lg is 1.25rem (20px)
$btn-font-size-xs:   .75rem;  // 12px
$btn-font-size-xl:  1.375rem; // 22px
$btn-font-size-xxl: 1.625rem; // 26px

// Button radiuses (all the same)
$btn-border-radius:     $border-radius-sm;
$btn-border-radius-xs:  $btn-border-radius;
$btn-border-radius-sm:  $btn-border-radius;
$btn-border-radius-lg:  $btn-border-radius;
$btn-border-radius-xl:  $btn-border-radius;
$btn-border-radius-xxl: $btn-border-radius;

// Button transition (I just added the filter one)
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, filter .15s ease-in-out;

// Button spacing
$btn-set-spacing-y: 0.5rem; // Was $btn-block-spacing-y in Bootstrap 4

// Button outlines
$enable-outline-buttons: true !default;

// Border shadow - can double up with $box-shadow 
$box-shadow-sm: 0 .25rem .75rem rgba($navy, .05);
$box-shadow:    0 .5rem 1rem rgba($navy, .05);
$box-shadow-lg: 0 .5rem 1rem rgba($navy, .1);

// Pagination 
$pagination-border-color:          $navy-25;
$pagination-hover-bg:              color.mix(#fff, $navy, 90%);
$pagination-hover-border-color:    $navy-75;
$pagination-disabled-color:        $navy-50;
$pagination-disabled-border-color: $navy-25;

// Tables
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;

// Alerts (lighten the backgrounds a tad)
$alert-bg-scale:     -85%;
$alert-border-scale: -75%;

// Marks
$mark-padding: 0;
$mark-bg: transparent;

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2, // Only this has changed, to bring it in line with $grid-gutter-width
);

// Boxes (GAYL-specific)
$box-padding-x: 1.5rem;
$box-padding-y: $box-padding-x;
