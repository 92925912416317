//
// Countdown
//

.countdown {
	gap: .5rem;
	display: flex;
	flex-direction: row;
}

.countdown__tile {
	color: $grey;
	flex-grow: 1;
	display: flex;
	min-width: 2ch;
	margin: .25rem 0;
	padding: 0 .5rem;
	font-size: 1.25rem;
	text-align: center;
	align-items: center;
	background: $grey-10;
	border-radius: 0.5rem;
	justify-content: center;
}

.countdown__split {
	font-size: 3rem;
	background: linear-gradient(0deg, $grey-10 49%, $grey-20 50%, $grey-15 51%);
}

.countdown--lg {
	.countdown__tile {
		padding: 0 2rem;
		font-size: 2rem;
	}

	.countdown__split {
		font-size: 4.5rem;
	}
}