//
// Band
// NB - no background-colour options here, they should be set using .bg-xxx helper classes
//

// Master band

$band-padding: 3rem;

.band {
	--band-padding: #{$band-padding};
	padding-top: var(--band-padding);
	padding-bottom: var(--band-padding);
	color: var(--body-color);
	position: relative;
}

.band--dark {
	--text-color-rgb: 255, 255, 255;
	--link-color: #fff;
	--link-hover-color: #fff;
	--paragraph-opacity: #{math.div(1 + $paragraph-text-opacity, 2)};
	color: #fff;

	.box {
		--link-color: #{$link-color};
		--link-hover-color: #{$link-color};
	}
}

// Sizing options
.band-xs {
	--band-padding: #{math.div($band-padding, 2)};
}

.band-sm {
	--band-padding: #{$grid-gutter-width};
}

.band-lg {
	--band-padding: clamp(#{$band-padding}, 6vw, #{$band-padding} * 1.5);
}

.band-xl {
	--band-padding: clamp(#{$band-padding}, 8vw, #{$band-padding} * 2);
}

.band-xxl {
	--band-padding: clamp(#{$band-padding}, 10vw, #{$band-padding} * 2.5);
}

// Full-width alerts (part of main templates so they appear above all content)

.band--alert:not([class*=alert-]) {
	padding: 1rem 0;

	& + .band {
		padding-top: 0;
	}

	& + div > .dashboard-boxes:first-child > .box:first-child {
		margin-top: 0;
	}
}

.band--alert[class*=alert-] {
	margin: 0 !important;
	padding: 0 !important;
	border-radius: 0 !important;

	.container {
		position: relative;
		padding-top: var(--alert-padding-y);
		padding-bottom: var(--alert-padding-y);
	}
}

// Alternating

.bands-striped > .band:nth-child(odd):not([class*=bg-]) {
	background: #fff !important;
}

.bands-striped > .band:nth-child(even):not([class*=bg-]) {
	background: $navy-5 !important;
}

// Image backgrounds

.band--has-background {
	overflow: hidden;
	position: relative;

	> .container {
		position: relative;
	}
}

.band__bg {
	@extend .hero__bg !optional;
	@extend .hero__bg--center !optional;
}

.band__bg--center {
	left: 0;
	right: 0;
	margin: 0 auto;
}

.band__bg--bottom {
	bottom: 0 !important;
	top: unset !important;
	height: unset !important;
}

.band__bg--float {
	object-fit: contain !important;
}

.band__bg--shape { // this removes the aspect ratio and stops shape being cut-off
	max-width: unset;
	object-fit: unset !important;
}

// Make a container pos-abs so you can do different backgrounds

.band--has-behind {
	overflow: hidden;
	position: relative;

	.container:not(.band__behind) {
		position: relative; // Sorts out z-index issues
	}
}
.band__behind {
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	position: absolute;
	pointer-events: none;

	.row > * {
		position: relative;
	}
}

.band__behind,
.band__behind-md {
	.row {
		height: 100%;
	}
}

@include media-breakpoint-up(md) {
	.band__behind-md {
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		position: absolute;
		pointer-events: none;

		.row > * {
			position: relative;
		}
	}
}

// Band header
.band-header {
	:last-child {
		margin-bottom: 0;
	}

	&:has(+ .band),
	&:has(+ a + .band) { // Covers a.visually-hidden links in between
		padding-bottom: 0;
	}
}

@include media-breakpoint-down(md) {
	.band-header, 
	.band-header + .band {
		--band-padding: #{$grid-gutter-width};
	}
}

// CTA Band
.band-cta {
	text-align: center;

	.row {
		justify-content: center;

		> :has(p, h1, h2, h3) {
			align-self: center;

			> :not(:first-child) {
				margin-top: $headings-margin-bottom * 1.5;
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		text-align: left;

		.row {
			justify-content: space-between;
		}
	}
}

// Band image breakouts
.band { 
	&:has(.band-img-md-breakout) {
		padding-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		// Move the padding from band to inner content
		&:has(.band-img-md-breakout) {
			padding-top: 0;

			.row > :not(:has(.band-img-md-breakout)) {
				padding-top: var(--band-padding);
				padding-bottom: var(--band-padding);
			}
		}

		.row > :has(.band-img-md-breakout) { // Can't use :has(> .band-img-breakout due to nested nature of <picture><img>)
			position: relative;
		}

		.band-img-md-breakout {
			bottom: 0;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			max-height: calc(100% + var(--band-padding));
		}
	}
}
