// Get all the colours in use
$colour-list-master: flatten(join((), (
    $colour-list-alerts,
    $colour-list-backgrounds,
    $colour-list-borders,
    $colour-list-buttons,
    $colour-list-colour-bgs,
    $colour-list-coloured-links,
    $colour-list-list-groups,
    $colour-list-tables,
    $colour-list-text,
)));

// Temporarily overriding $theme-colors allows us to only build components for specific colours
$theme-colors: filter-master-colors($colour-list-master);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// Now we can use Bootstrap's partial
@import "~BootstrapSass/root";

// Other additions to :root
:root {
    --#{$prefix}bg-opacity: 1;
    --#{$prefix}text-opacity: 1;
    --#{$prefix}text-color-rgb: var(--#{$prefix}body-color-rgb);
    --#{$prefix}font-headings: #{inspect($headings-font-family)};
}

// Reset $theme-colours
$theme-colors: ();