/*
 * Account stylings
 * Common styles only - use product sub-folders for product-specific styles
 */

// Icons (bigger ones on account home)

.account--icon {
	margin: 1rem 0;
	color: $grey;
	display: block;
	font-size: 18px;
	
	@include media-breakpoint-up(md) {
		display: inline-block;
	}
}
.account--icon .fa-layers {
	color: $navy;
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	transition: color .3s ease-in-out;
}
.account--icon:hover, .account--icon:focus {
	color: $navy;
	text-decoration: none;

	.fa-layers {
		color: $navy-75;
	}
}

// Confirmation/warning messages
.band > .container > .alert-account {
	margin-top: -20px;
	margin-bottom: 20px;
}


@if $stylesheet == "donate" {
	// Add page updates modal, includes hack to show scrollbar all the time
	.js-page-update-list {
		max-height: 50vh;
	}
}

@if $stylesheet != "admin" {
	// Statues
	.status--D, 
	.status--I {
		color: $red !important; // Declined, Incorrect
	}

	.status--A {
		color: $green !important; // Approved
	}

	.status--P, 
	.status--W, 
	.status--S {
		color: $orange !important; // Pending, Waiting, Submitted
	}

	.status--C {
		color: $grey-60 !important; // Closed
	}

	.status--N {
		color: $marine !important; // New
	}
}

// Circle profile image cropper

.cropper-circle {
	.cropper-view-box,
	.cropper-face {
		border-radius: 50%;
	}
}
