// Greys
$grey-5:  #f5f5f5; // Original redesign value was #f7f7f7 but a bit too light?
$grey-10: #f0f0f0;
$grey-15: #ebebeb;
$grey-20: #e6e6e6;
$grey-30: #d4d4d4;
$grey-40: #b5b5b5;
$grey-50: #8c8c8c;
$grey-60: #696969;
$grey-70: #4a4a4a;
$grey-80: #2b2b2b;
$grey-90: #141414;

$silver: $grey-10;
$border-color: $grey-10;

// Use for Bootstrap's variables as well
// Don't apply until we do the colour changes - the existing greys are darker than what Bootstrap uses
// We should probably use Bootstrap's greys, maybe with some minor tweaks
// $gray-100: $grey-10;
// $gray-200: $grey-20;
// $gray-300: $grey-30;
// $gray-400: $grey-40;
// $gray-500: $grey-50;
// $gray-600: $grey-60;
// $gray-700: $grey-70;
// $gray-800: $grey-80;
// $gray-900: $grey-90;

$colour-map-greys: (
	'grey-5': $grey-5,
	'grey-10': $grey-10,
	'grey-20': $grey-20,
	'grey-30': $grey-30,
	'grey-40': $grey-40,
	'grey-50': $grey-50,
	'grey-60': $grey-60,
	'grey-70': $grey-70,
	'grey-80': $grey-80,
	'grey-90': $grey-90,
	'silver': $silver
);

// Add to master colour map
$colour-map-master: map.merge($colour-map-master, $colour-map-greys);

// And extract keys to make a list
$colour-list-greys: map.keys($colour-map-greys);