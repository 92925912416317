// Tabs
.tabs {
	margin: 0;
	z-index: 2;
	overflow-x: auto;
	list-style: none;
	padding: 1rem 0 0;
	position: relative;
	white-space: nowrap;

	&:not(.d-none) + .box {
		border-top-left-radius: 0;
	}

	button {
		border: 0;
		color: $navy;
		outline: none;
		background: $grey-20;
		display: inline-block;
		box-shadow: $box-shadow;
		padding: .5rem 1rem .25rem;
		margin: .125rem .25rem 0 0;
		transition: $transition-base;
		border-radius: $border-radius $border-radius 0 0;

		@include media-breakpoint-up(md) {
			padding: 1rem 2rem .5rem;
		}

		&:hover {
			color: $navy;
			background: $grey-30;
			text-decoration: none;
		}

		&:focus-visible {
			outline: auto; // For accessibility
		}

		&.active, 
		&.active:hover {
			background: #fff;
		}
	}
}

@include media-breakpoint-up(md) {
	.tabs.text-lg button {
		padding: 15px 30px 10px;
	}
}

.tab-content.fade {
	display: none;
	transition: all 0.15s linear;

	&.active {
		display: block;
	}
	
	.box__tab {
		border-top-left-radius: 0;
	}
}

.boxes__tab {
	filter: drop-shadow($box-shadow-lg);
	
	.box {
		box-shadow: none;

		&:hover {
			box-shadow: none;
		}
	}
}