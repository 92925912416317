/*
 * Nav
 * Desktop-only and mobile-only styles are grouped togther to reduce lots of separate media queries
 * 
 * .nav-container (full width, just sets colours)
 *  > .container
 *   > #nav-menu
 *    > .nav-toggler (button for hiding/showing on mobile/tablet)
 *    > ul
 *     > li
 *      > a.nav-item
 */

// Set the colours
$nav-bg-main: $navy-5;
$nav-bg-hover: $navy-10;
$nav-bg-dropdown: $navy-15;
$nav-bg-dropdown-hover: $navy-20;

// Other variables
$nav-border-bottom: .25rem; // This is added to main's first child if it uses .bg-navy-5 and therefore matches the nav colour
$nav-item-padding-x-mobile:  $input-btn-padding-x;
$nav-item-padding-y-mobile:  $input-btn-padding-x; // Deliberately not $input-btn-padding-y
$nav-item-padding-x-desktop: $input-btn-padding-x-lg;
$nav-item-padding-y-desktop: $input-btn-padding-x-lg;
$nav-dropdown-item-padding:  $input-btn-padding-x;

// z-index
$zindex-nav: $zindex-dropdown;
$zindex-nav-backdrop: $zindex-nav - 1;
$zindex-nav-mobile: $zindex-offcanvas;

//
// All (Desktop & Mobile)
//

// Container
.nav-container {
	--paragraph-opacity: 1;

	padding: 0;
	position: relative;
	background: $nav-bg-main;
}

// Mobile nav toggle
.nav-toggler {
	padding: 0;
	line-height: 1;
	color: $navy;
	background-color: transparent;

	// "Menu" text
	span {
		line-height: 2rem;
	}
}

.band--dark .nav-toggler {
	color: white;
}

// Desktop nav
#nav-menu > ul {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
}

// Nav items
.nav-item {
	color: $navy;
	width: 100%;
	height: 100%;
	font-size: 1rem;
	align-items: center;
	justify-content: left;
	text-decoration: none;
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: $nav-item-padding-x-mobile;
	padding: $nav-item-padding-y-mobile $nav-item-padding-x-mobile;
	transition: background-color .2s ease-in-out; // Not using $transition-base so border-radius changes instantly
}

.nav-item:hover {
	text-decoration: none;
	background-color: $nav-bg-hover;
}

.nav-item:focus,
.nav-item[aria-expanded='true'] {
	text-decoration: none;
	background-color: $nav-bg-dropdown;
}

@if $stylesheet == 'giveasyoulive' {
	.nav-item--doubledonations {
		background-color: $gold-75;
	}
	.nav-item--doubledonations:hover, 
	.nav-item--doubledonations:focus {
		background-color: tint($gold-75, 80%);
	}
}

//
// Desktop Only
//

@include media-breakpoint-up(lg) { 
	.nav-container {
		z-index: $zindex-nav;
		box-shadow: 0 0 2rem rgba($navy, 5%);
	}

	// Sub-container
	#nav-menu {
		display: flex;
		overflow: hidden;
		flex-basis: auto;
	}

	// Desktop nav
	#nav-menu > ul {
		display: flex;
	}

	// Nav items
	.nav-item {
		width: auto;
		text-wrap: nowrap;
		padding: $nav-item-padding-y-desktop $nav-item-padding-x-desktop;
	}
}

//
// Mobile Only
//

@include media-breakpoint-down(lg) {
	// Nav menu - mobile
	#nav-menu {
		top: 0;
		bottom: 0;
		left: 100%;
		width: 25rem; // 400px;
		z-index: $zindex-nav-mobile;
		max-width: 85vw;
		position: fixed;
		overflow-y: auto;
		visibility: hidden;
		padding: $grid-gutter-width;
		background-color: $nav-bg-main;
		transition: $transition-base;

		// And when opened
		&.open {
			visibility: visible;
			transform: translateX(-100%);
		}

		// Toggle back
		.nav-toggler {
			color: $navy;
			display: block;
			margin-left: auto;
		}

		.btn {
			margin-top: 10px;
		}
	}

	.nav-item {
		border-radius: $btn-border-radius;
	}

	.header-search-container {
		margin-top: calc(var(--gutter-x) * .5);
	    margin-bottom: calc(var(--gutter-x) * -.5); // Undoes padding in header > container
	}
}

@include media-breakpoint-down(sm) {
	.header-search-container {
		background: $navy-75; // Only works on XS due to side padding from .container
	}
}