/* CSS for Feefo review components */

/* Button */

.btn-feefo {
	@include button-variant($feefo, $feefo);

	max-width: 100%;
	text-align: left;
	font-size: .875rem;
	display: inline-block;

	.btn-feefo__rating {
		display: flex;
	}

	.btn-feefo__logo {
		width: clamp(1px, 60%, 4.5rem);

		img {
			width: auto;
			margin-right: .5rem;
			margin-bottom: 3px;
		}
	}

	.btn-feefo__stars {
		flex-grow: 1;
		max-width: 60%; // Needed to prevent wierd flex bug on iOS
		max-width: clamp(1px, 60%, 4.5rem); // Needed to prevent wierd flex bug on iOS
		white-space: nowrap;
		margin-bottom: 3px;
  	}

	.btn-feefo__award,
	.btn-feefo__info {
		display: flex;
		text-align: left;
		line-height: 1.2;
	}

	.btn-feefo__award {
		color: $grey;
		padding: 0.125rem .5rem;
		font-size: .75rem;
		background: $grey-10;
		border-radius: math.div($border-radius-sm, 2);
		margin-top: 0.125rem;
		// white-space: nowrap;
	}

	.star {
		width: 20%;
		display: inline-block;

		svg {
			max-width: 100% !important;
		}
	}
}

/* Summary Section  */

.reviews__summary {
	font-size: .875rem;
}

.reviews__stars {
	font-size: 1.5rem;
}

.reviews__rating {
	letter-spacing: -1px;
	display: inline-block;
}

.reviews__logo {
	max-height: 31px; // Height of button next to Feefo logo
}