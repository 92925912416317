// Christmas

@import "~Sass/common/countdown-plugin";

//
// Variables
//

$christmas-ground:      #dae6e2;
$christmas-ground-lite: #f7f9fa;
$christmas-red:         #e63026;
$christmas-sky:         #a6ceee;
$christmas-sky-lite:    #faf3f0;

//
// Utility helpers - adding to similar ones in Helpers.scss and Bootstrap
//

.r-5 {
	transform: rotate(5deg);
}

// 
// Backgrounds
//

.bg-christmas-ground {
	background: $christmas-ground;
}

.bg-christmas-sky {
	background: $christmas-sky;
	background: linear-gradient(180deg, $christmas-sky, $christmas-sky-lite 30vw, $christmas-sky-lite 50vw, $christmas-ground-lite 80vw);
}

.bg-christmas-container {
	img {
		padding-top: 10vw;
		width: clamp(800px, 100%, 100%);
		max-width: unset;
	}

	#section-progress & img {
		padding-top: 4vw;
	}
}

//
// Text
//

.text-christmas-sky { // Used for wave SVG
	color: $christmas-sky;
}

// Can't remember what this is for!
// $theme-colors: filter-master-colors(list.join($colour-list-buttons, ('marine', 'orange')));
// @each $color, $value in $theme-colors {
// 	.btn-outline-#{$color}:not(:disabled):not(.disabled).active {
// 		background-color: $value;
// 		color: color-contrast($value, $grey, $white);
// 	}
// }
// $theme-colors: ();

//
// Progress section
//

.christmas-sledge__container {
	margin-top: -4%;
	position: relative;
	display: inline-block;
	transform: translateX(-75%);
	width: clamp(10rem, 20vw, 15rem);

	&:after {
		bottom: -1%;
		right: 95%;
		content: "";
		opacity: 0.7;
		height: 1rem;
		width: 100vw;
		display: block;
		position: absolute;
		background: url("/images/common/christmas/2022/tracks.svg") center right;
	}
}

.christmas-sledge {
	z-index: 10;
	position: relative;
}

.christmas-progress-complete > div { // the > div isn't really necessary but makes it easier to debug in browser
	position: relative;
	height: clamp(6rem, 8vw, 8rem);

	img, svg {
		left: 0;
		bottom: 0;
		position: absolute;
	}
}

.christmas-progress-heading {
	padding: 2rem 6rem;
	padding: 2rem clamp(1rem, 6vw, 6rem);
	background: rgba($white, 0.8);
	border-radius: 10vw;
}

.christmas-progress-marker {
	padding: 1rem;
	color: $white;
	background: $brand;
	position: relative;
	border-radius: 3rem;
	font-size: 2.125rem;
	font-size: clamp(1.125rem, 2.125vw, 2.125rem);

	&.line-after:after {
		left: 100%;
		width: 150%;
		height: 3px;
		content: '';
		position: absolute;
		top: calc(50% - 1px);
		background: $brand-50;
	}
}

.christmas-progress-marker--fade {
	background: $brand-50;
}

.christmas-houses {
	.col {
		position: relative;
	}

	.christmas-houses-tree--left,
	.christmas-houses-tree--right {
		left: -5%;
		bottom: -5%;
		width: 20%;
		position: absolute;
	}

	.christmas-houses-tree--right {
		left: unset;
		right: -5%;
		width: 23%;
	}
}

.christmas-houses-people > div { // the > div isn't really necessary but makes it easier to debug in browser
	position: relative;
	height: 5rem;
	height: clamp(4rem, 5vw, 5rem);

	img, svg {
		left: 0;
		bottom: 0;
		position: absolute;
		max-height: clamp(5rem, 8vw, 7rem);
	}
}

.christmas-hero-people > div { // the > div isn't really necessary but makes it easier to debug in browser
	position: relative;
	height: 4rem;
	height: clamp(3rem, 4vw, 4rem);

	img, svg {
		left: 0;
		bottom: 0;
		position: absolute;
		max-height: clamp(7rem, 10vw, 9rem);
	}

	.christmas-hero-tree {
		transform: translateX(var(--xmasTreeX, 0)) translateY(var(--xmasTreeY, 0));
		max-height: clamp(5rem, 8vw, var(--xmasTreeH, 7rem));
	}
}

.christmas-hero-people--lg > div {
	height: 6rem;
	height: clamp(4rem, 6vw, 6rem);

	img, svg {
		&:not(.christmas-child) {
			max-height: clamp(9rem, 13vw, 12rem);
		}
	}
}

.christmas-searchbox {
	padding: 4rem 6rem;
	padding: 4rem clamp(1rem, 6vw, 6rem);
}

//
// Social section
//

.christmas-social-icon {
	&:hover .fa-circle {
		opacity: 0.7;
	}

	.fa-circle {
		stroke: #fff;
		stroke-width: 2rem;
		transition: $transition-base;
	}
}

// Nav
.nav-item--christmas {
	color: #FFF;
	background-color: $christmas-red;
}
.nav-item--christmas:hover, 
.nav-item--christmas:focus,
.nav-item--christmas[aria-expanded=true] {
	color: #FFF;
	background-color: tint($christmas-red, 80%);
}

//
// Hero
//

.christmas-snowglobe-glitter-container {
	position: relative;
	display: inline-block;
	will-change: transform;
	transform: rotate(0deg);
	animation-duration: 1.5s;

	.snow {
		--snowRepeat: 2;
		--snowSize: 15px;
		--snowEnd: clamp(15rem, 50vw, 35rem);

		width: 66%;
		margin-left: 17%;
	}
}

.christmas-snowglobe-glitter {
	width: auto;
	height: clamp(15rem, 50vw, 35rem);
}

@media (prefers-reduced-motion: no-preference) {
	.christmas-snowglobe-shake {
		animation-name: snowglobe;
	}

	@keyframes snowglobe {
		15%, 45%, 75% {
			transform: rotate(2deg);
		}
		30%, 60%, 90% {
			transform: rotate(-2deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
}

.christmas-snowglobe-glitter-snow {
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(#fff,0);
	transition: background .5s ease-in-out;
	clip-path: polygon(18.5% 88%, 18.5% 35%, 27% 20%, 42% 11%, 58% 11%, 73% 20%, 81.5% 35%, 81.5% 88%);

	&:nth-child(2) {
		-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(#000,1)), to(rgba(#000,0))); // This is used for the below, but as to be here to work with the transition
	}

	.christmas-snowglobe-shake &:nth-child(2) {
		background: white;
	}
}

.christmas-add-shadow {
	position: relative;
	// display: inline-block; // Sorts out width issue on Chrome, completely breaks other browsers!

	> * {
		position: relative;
	}

	> picture {
		width: 100%;
		height: 100%;
	}

	&:before {
		left: -15%;
		width: 130%;
		content: '';
		opacity: 0.4;
		height: 1.5rem;
		height: clamp(.5rem, 10%, 1.5rem);
		bottom: -.5rem;
		display: block;
		border-radius: 50%;
		position: absolute;
		background-color: #ccd3d6;
		mix-blend-mode: multiply;
	}
}

//
// Snow
// Adapted from https://codepen.io/alphardex/pen/dyPorwJ => changed to use --var and simplified variable names
//

@media (prefers-reduced-motion: no-preference) {
	.snow-overlay {
		z-index: 10;
	}

	.snow {
		--snowEnd: 200vh;
		--snowRepeat: infinite;
		position: relative;
		pointer-events: none;

		> div {
			width: var(--snowSize, 10px);
			height: var(--snowSize, 10px);
			background: white;
			position: absolute;
			border-radius: 50%;
			will-change: transform;
			opacity: var(--opacity);
			transform: translate(var(--xa), -40px) scale(var(--scale));
			animation: fall var(--duration) var(--delay) linear var(--snowRepeat);
		}
	}

	@keyframes fall {
		95% {
			opacity: var(--opacity);
		}
		100% {
			opacity: 0;
			transform: translate(var(--xc), var(--snowEnd)) scale(var(--scale));
		}
	}
}

//
// Gift Tag
// 

#gift-tag {
	color: $navy;
	text-align: center;
	position: relative;
	max-width: map.get($container-max-widths,"xl");

	> div {
		width: 100%;
		padding: 0 20%;
		position: absolute;
	}

	span {
		margin: 0 auto;
	}
	
	.to {
		top: 25%;
		height: 5%;
	}

	.greeting {
		top: 32%;
		height: 10%;
		font-weight: bold;
		font-size: 1.25rem;
	}

	.from {
		top: 44%;
		height: 5%;
	}

	.supporting {
		bottom: 4%;
		height: 15%;
		color: $navy;
		padding: 0 8%;

		img {
			border-radius: .5rem;
		}
	}
}
