// Form stuff for page creation

@import "~Sass/common/image-gallery";
@import "~Sass/donate/page-progress-bar";

// For auto-refreshing donation lists (also used by charity pages)
#donation-list .collapse {
	transition: all .5s ease;
}
#donation-list .collapsing {
	background: $green-25;
}

// Streaming wrapper
.video-wrapper {
	width: 100%;
	line-height: 0;
}

// Multiple errors in streaming section
#my-stream .alert-red + .alert-red {
	padding-top: 0;
}

.activity-summary {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	border: 2px solid $grey-20;
	background: var(--box-bg, #fff);
	border-radius: var(--box-border-radius, .5rem);

	> div {
		margin: -1px;
		display: flex;
		flex: 1 1 25%;
		min-width: 100px;
		padding: $btn-padding-y;
		border: 1px solid $grey-30;

		@include media-breakpoint-up(md) {
			flex: 1 1 auto;
		}

		& > div {
			margin: auto;
		}
	}
}

.activity-photo {
	transition: all .3s ease;

	&.col-12 p {
		display: none;
	}
}

// Reactions

.btn-reaction {
	stroke: currentColor;
	stroke-opacity: 0.5;

	.js-reaction-counter:not(:empty) {
		padding-right: .125rem;
	}
}

.btn-reaction-on svg {
	animation: react .5s ease-in-out forwards;
}

@keyframes react {
	0%, 100% {
		stroke-width: 0%;
		filter: none;
	}
	50% { 
		stroke-width: 50%;
		filter: drop-shadow(0 0 5px currentColor); 
	}
}
