// Default styles
@import "~Vendor/spectrum-colorpicker/spectrum.css";

// Overrides to make it look more like normal inputs

// Main input
.sp-replacer {
	display: flex;
	padding: 0.5rem 1rem;
	transition: $transition-base;
	border-radius: $border-radius;
	border-color: $input-border-color;
	background-color: $input-bg;

	&:hover, &.sp-active {
		border-color: $input-border-color;
	}
}

// Preview colour
.sp-preview {
	flex-grow: 1;
	margin-right: .875rem;
	border-color: $input-border-color;
}

// The pop-up
.sp-container {
	font-family: unset;
	border-radius: $border-radius;
	border-color: $input-border-color;

	input, button {
		font-family: unset;
	}

	.sp-choose, 
	.sp-choose:hover {
		color: #fff;
		background: $navy;
		text-shadow: unset;
		border-color: $navy;
		transition: $transition-base;
		font-size: $btn-font-size-xs;
		line-height: $btn-line-height;
		border-radius: $btn-border-radius-sm;
		padding: $btn-padding-y-xs $btn-padding-x-xs;
	}

	.sp-choose:hover {
		background: shade-color($navy, $btn-hover-bg-shade-amount);
	}
}

// Where you edit the written hex value
.sp-input {
	background: #fff;
	border: 1px solid $input-border-color;
}

.sp-cancel {
	float: left;
	color: $input-color !important;
	margin-top: 0.25rem;

	&:hover {
		color: $input-color !important;
	}
}

.sp-button-container {
	width: 100%;
	text-align: right;
}