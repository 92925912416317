// Temporarily overriding $theme-colors allows us to only build components for specific colours
$theme-colors: filter-master-colors($colour-list-coloured-links);

// Now we can use Bootstrap's partial
// @import "~BootstrapSass/helpers/colored-links";

// Actually, let's do our own styles

$link-block-thickness: 0.2em;
$link-block-thickness-hover: 0.4em;
$link-block-thickness-safety: 0.1em; // Because of px rounding, we want a bit more to be hidden than we think we need to ensure the bottom edge stays fixed

// Master
a[class*=link-block-] {
	color: currentColor;
	text-decoration: none;
	background-size: 100% ($link-block-thickness-hover + $link-block-thickness-safety);
	background-position: 0% calc(100% + ($link-block-thickness-hover - $link-block-thickness + $link-block-thickness-safety));
	background-repeat: no-repeat;
	transition: background-position .2s;
	background-image: linear-gradient(var(--link-bg, $brand-50), var(--link-bg, $brand-50)); // Has to be a gradient to work

	&:hover, &:focus, &:active {
		text-decoration: none;
		background-position: 0% calc(100% + $link-block-thickness-safety);
	}
}

// Themes
@each $color, $value in $theme-colors {
	.link-block-#{$color} {
		$link-block-colour: map.get($colour-map-master, "#{$color}-50");

    	@if $link-block-colour == null {
			$link-block-colour: mix(#fff, $value, 50%);
		}

		--link-bg: #{$link-block-colour};
	}
}

.link-block-alert {
	--link-bg: var(--alert-border-color);
}

// Reset $theme-colours
$theme-colors: ();
