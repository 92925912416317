.video-container {
	position: relative;

	> a {
		top: 0;
		left: 0;
		z-index: 10;
		position: absolute;

		img {
			transition: $transition-base;
		}

		.fa-layers {
			top: 50%;
			left: 50%;
			z-index: 2;
			max-width: 25%;
			position: absolute;
			transition: $transition-base;
			transform: translateX(-50%) translateY(-50%);

			// Override .icon-block (but class still needed for children)
			padding: 0;
			height: 100%;
		}

		&:hover {
			img {
				filter: drop-shadow($box-shadow-lg);
			}
			.fa-layers {
				max-width: 27%;
				filter: drop-shadow($box-shadow-lg);
			}
		}
	}

	> video:nth-child(2) {
		visibility: hidden;
	}
}

.video-pip--above, 
.video-pip--below {
	top: 5vh;
	right: 5vw;
	position: fixed;
	max-width: 90vw;
	max-height: 90vh;
	z-index: $zindex-sticky;
	box-shadow: $box-shadow-lg;
}

.video-pip--below {
	bottom: 5vh;
	top: unset;
}

.fa-layers .fa-play {
	transform: translateX(5%);
}