/*
 * Footer
 */

// Variables

$footer-bg: $navy;
$footer-bg-alt: $navy-75;
$footer-link-margin-y: $btn-padding-y * 2;

// Structure

.website-footer {
	margin-top: auto;
}

footer {
	--body-color-rgb: 255, 255, 255;
	--text-color-rgb: 255, 255, 255;
	color: #fff;
	background: $footer-bg;
}

// Social Strip

.social-footer {
	background: $footer-bg-alt;

	.col {
		max-width: 3.5rem;
	}

	a {
		transition: $transition-base;

		&:hover {
			opacity: 0.5;
		}
	}
}

.text-instagram.fa-layers, .fa-layers.text-instagram:hover {
	.fa-circle * {
		fill: url('#instagram-gradient');
	}
}

// Links

.quicklinks {
	a {
		// Applies to headers on mobile as well
		--link-color: #fff;
		--link-hover-color: #fff;

		&:hover, &:focus, &:active {
			text-decoration: none;
		}
	}

	li {
		margin: $footer-link-margin-y 0;

		a {
			opacity: 0.8;

			// Copied from colored-links.scss but changed position so line only appears on hover
			text-decoration: none;
			background-size: 100% 40%;
			background-position: 0% 200%;
			background-repeat: no-repeat;
			transition: background-position .2s;
			background-image: linear-gradient($navy-25, $navy-25); // Has to be a gradient to work

			&:hover, &:focus, &:active {
				background-position: 0% 140%;
			}
		}
	}
}

// Social proof logos

.social-proof {
	img:not(.btn-feefo__logo img) {
		width: auto;
		max-height: 55px; // Height of Feefo button

		&:hover {
			transition: $transition-fade;
			opacity: 0.8;
		}
	}
}

// Company info

.footer {
	--text-color-rgb: 255, 255, 255;
	--link-color: #{rgba(#fff, $paragraph-text-opacity)};
	--link-hover-color: #fff;
	padding: var(--footer-padding, 2rem) 0;

	&:only-child {
		padding: var(--footer-padding, 1rem) 0;
	}

	p:last-of-type {
		margin: 0;
	}
}

.has-lite-footer {
	--footer-padding: 1rem;
}