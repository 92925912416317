// @import "~Vendor/britecharts/src/styles/britecharts";
// Copy of Britechart's SCSS file, but we don't want all of it and we want to override bits of it too to avoid repetitive JS and redundant CSS

// Base Styles
@import "~Vendor/britecharts/src/styles/base/_reset.scss";
// @import "~Vendor/britecharts/src/styles/base/_typography.scss";

// Helpers
@import "~Vendor/britecharts/src/styles/helpers/_variables.scss";
@import "~Vendor/britecharts/src/styles/helpers/loadingStates.scss";

// Common Elements
@import "~Vendor/britecharts/src/styles/common/_grid.scss";
@import "~Vendor/britecharts/src/styles/common/_axes.scss";
@import "~Vendor/britecharts/src/styles/common/_tooltip.scss";
// @import "~Vendor/britecharts/src/styles/common/_legend.scss";

// Charts
// @import "~Vendor/britecharts/src/styles/charts/bar.scss";
// @import "~Vendor/britecharts/src/styles/charts/donut.scss";
@import "~Vendor/britecharts/src/styles/charts/line.scss";
// @import "~Vendor/britecharts/src/styles/charts/scatter-plot.scss";
// @import "~Vendor/britecharts/src/styles/charts/sparkline.scss";
// @import "~Vendor/britecharts/src/styles/charts/stacked-area.scss";
// @import "~Vendor/britecharts/src/styles/charts/stacked-bar.scss";
// @import "~Vendor/britecharts/src/styles/charts/grouped-bar.scss";
// @import "~Vendor/britecharts/src/styles/charts/step.scss";
// @import "~Vendor/britecharts/src/styles/charts/brush.scss";
// @import "~Vendor/britecharts/src/styles/charts/bullet.scss";

// Custom 

// Font override. Hopefully temporary until I can work out how to fix it.
.britechart {
	margin-bottom: 1rem;

	text {
		font-size: 12px;
		font-family: oxygen !important;
	}
}