//
// Extra button styles
//

// This enabled us to space the text and the icon out
.btn {
	align-items: center;
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: var(--btn-padding-x);
	justify-content: center;
	letter-spacing: 0.5px;

	// Force hover styles when filling an btn-outline-* button
	&[class*=bg-]:hover {
		background: var(--btn-hover-bg) !important
	}

	// Override FontAwesome widths (default is 1.25em - em NOT rem)
	// This keeps the gap between text and padding equal to the button padding
	// It is also picked up by the :empty helper in helpers.scss which prevents CLS
	// Every icon can have a different width, so only do it for frequently used button-icons
	.fa-fw {
		&.fa-angle-left,
		&.fa-angle-right {
			--fa-fw-width: 0.625em;
		}
	}
}

// Button that looks like an input - useful for when next to each other
.btn-input {
	@include button-variant(
		$background: $input-bg,
		$border: $input-border-color,
		$color: $input-color,
		$hover-background: shade-color($input-bg, 5%),
		$active-background: shade-color($input-bg, 5%),
	);
	font-family: var(--font-sans-serif);
	letter-spacing: 0;
}

// If needed in future, update to use variables
// .btn-custom {
// 	transition: $transition-base;
// 	color: var(--customButtonText);
// 	background: var(--customButton);
	
// 	&:hover {
// 		filter: brightness(0.9);
// 		color: var(--customButtonText);
// 		background: var(--customButton);
// 	}
// }

//
// A set of buttons (i.e. a group but separate)
//

// Adds spacing between buttons
.btn-set {
	--btn-set-spacing: #{$btn-set-spacing-y};

	gap: var(--btn-set-spacing);
	display: flex;
	flex-wrap: wrap;

	&:has(.btn-lg:last-child),
	&:has(.btn-xl:last-child) {
		--btn-set-spacing: #{$btn-set-spacing-y * 2};
	}

	> div:has(.btn-block) {
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		.btn:not(.btn-link) {
			width: 100%;
			display: grid;
		}
	}

	&.row,
	> .row {
		--gutter-y: var(--btn-set-spacing);
	}
}

.text-start {
	.btn-set {
		justify-content: flex-start;
	}
}

.text-center {
	.btn-set {
		justify-content: center;
	}
}

.text-end {
	.btn-set {
		justify-content: flex-end;
	}
}

// Makes vertical buttons have the same width (should be used with .btn-set)
.btn-auto {
	@include media-breakpoint-up(sm) {
		display: inline-grid; // Needs to be grid rather than flex due to issues in older Safari
		flex-direction: column;
	}
}

// Alignment helper - puts first button on right on tablet/desktop, helpful for forms etc where there is also a back button
.btn-align {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;

	@include media-breakpoint-up(md) {
		flex-direction: row-reverse;
	}
}

//
// Extra button sizes
//

.btn-xxl {
	@include button-size($btn-padding-y-xxl, $btn-padding-x-xxl, $btn-font-size-xxl, $btn-border-radius-xxl);
}

.btn-xl {
	@include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius-xl);
}

.btn-xs {
	@include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

//
// Make buttons full-width (.btn-block removed from Bootstrap v5 - todo: replace?)
//

.btn-block {
	width: 100%;
	display: grid;
}

.btn-fluid,
.btn-xs-block, // legacy - remove in future once all instances replaced
.btn-sm-block { // legacy - remove in future once all instances replaced
	@include media-breakpoint-down(md) {
		width: 100%;
		display: grid;
	}
}

//
// Button icons, e.g. GAYL heartcart in button
// The parent .btn is needed because otherwise it's easy to override
// Important: use ems not rems
// 
.btn .btn-icon {
	width: auto;
	height: 1.75em;
	margin: -.25em .25em -.25em;
}

// This is more for analytics - means all clicks will be assigned to the button and not the child icon
.btn i {
	pointer-events: none;
}

// Removing a Bootstrap style
.btn-check + .btn:hover {
	background-color: var(--btn-hover-bg);
}

.btn-app-store {
	width: auto;
	height: 3rem;
}