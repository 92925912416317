// animate.scss
// It is usually best for the animation element to be its own element, just to prevent CSS clashes. I don't want to fill this up with !important and there would be cases where that would be messy anyway.

.animate {
	animation-delay: .3s;
	animation-duration: .5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}

// This is added by the Animate JS class to stop off-screen animations from playing until viewable
.animate-hold,
.animate-hold .animate {
	animation-name: unset !important;
}

// Fade up animation

.animate--fadeup {
	opacity: 0;
	transform: translateY(10%);
	animation-name: animFU;
}

@keyframes animFU {
	100% { opacity: 1; transform: translateY(0); }
}

// Bounce up animation

.animate--bounceup {
	transform: translateY(100vh);
	animation-name: animBU;
}

@keyframes animBU {
	90%  { transform: translateY(-10vh); }
	100% { transform: translateY(0); }
}

// Flicker animation

.animate--flicker {
	animation-delay: 0s;
	animation-name: animF;
	animation-duration: 1s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
}

.animate--flicker + .animate--flicker {
	animation-delay: .5s;
}

@keyframes animF {
	10%, 90% { opacity: 1; }
	50%      { opacity: .2; }
}

// Pulse animation

.animate--pulse {
	animation-delay: 0s;
	animation-name: animP;
}

.animate--pulse-box {
	animation-delay: 0s;
	animation-name: animPB;
}

@keyframes animP {
	0%, 50%, 100% { box-shadow: 0 0 0 rgba($red, 0.5); }
	25%, 75% {  box-shadow: 0 0 2rem rgba($red, 0.5); }
}

@keyframes animPB {
	0%, 50%, 100% { box-shadow: $box-shadow; }
	25%, 75% {  box-shadow: 0 0 2rem rgba($red, 0.5); }
}

// .animate--rotate,
// .animate--rotate-alt {
// 	animation-name: animR;
// 	transform: rotate(0deg);
// 	animation-duration: 90s;
// 	animation-timing-function: linear;
// 	animation-iteration-count: infinite;
// }

// .animate--rotate-alt {
// 	animation-direction: reverse;
// }

// @keyframes animR {
// 	100% { transform: rotate(360deg); }
// }


// Grand total scrolling animation
.animate-totaliser {
	top: 0;
	left: 0;
	position: absolute;
	animation-delay: 0;
	animation-duration: 1s;
	animation-name: animTotal;

	span {
		display: block;
	}
}

.totaliser--comma {
	height: 1em;
	display: inline-block;
	transform: translateY(-20%);
}

.totaliser--number {
	width: 1ch;
	height: 1em;
	overflow: hidden;
	position: relative;
	display: inline-block;
}

@keyframes animTotal {
	100% { transform: translateY(-91%); } // 10/11 as a %
}
