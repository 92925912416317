// Circle, e.g. with "or" in it.
.divider-circle--container {
	margin-top: 1.25rem;
	position: relative;

	// This adds padding to the proceeding element (just using padding/margin would override any already set)
	& + *:before {
		content: '';
		height: 1rem; // Whilst this could be 1.25rem to match above, 1rem looks better
		display: block;
	}
}
.divider-circle {
	top: 0;
    left: 50%;
    z-index: 2;
    line-height: 1;
    aspect-ratio: 1;
    padding: 0.75rem;
    overflow: hidden;
    background: #fff;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    display: inline-table; // Bit meh but sorts Safari issues
    border: $btn-border-width solid $grey-20;
    transform: translate(-50%, -50%);
}

// Slants
// When applying slants, you will typically use top-left or top-right, as the second element will appear above the first element.
$slant-amount:       5vw;
$slant-offset:       calc(100% - #{$slant-amount});

// Slants - polygon(top left, top right, bottom right, bottom left) (i.e clockwise from top left)
$slant-top-left:     polygon(0            0 , 100% $slant-amount, 100%          100%, 0          100%);
$slant-top-right:    polygon(0 $slant-amount, 100%            0 , 100%          100%, 0          100%);
$slant-bottom-left:  polygon(0            0 , 100%            0 , 100% $slant-offset, 0          100%);
$slant-bottom-right: polygon(0            0 , 100%            0 , 100%          100%, 0 $slant-offset);

.slant {
	position: relative;
}

// Safari still uses the webkit variant
.slant--top-left {
	margin-top: -$slant-amount;
	-webkit-clip-path: $slant-top-left;
	clip-path: $slant-top-left;
}
.slant--top-right {
	margin-top: -$slant-amount;
	-webkit-clip-path: $slant-top-right;
	clip-path: $slant-top-right;
}
// .slant--bottom-left {
// 	margin-bottom: -$slant-amount;
// 	-webkit-clip-path: $slant-bottom-left;
// 	clip-path: $slant-bottom-left;
// }
// .slant--bottom-right {
// 	margin-bottom: -$slant-amount;
// 	-webkit-clip-path: $slant-bottom-right;
// 	clip-path: $slant-bottom-right;
// }

.slant-offset {
	padding-top: $slant-amount;
	padding-bottom: $slant-amount;
}
.slant-offset--top {
	padding-top: $slant-amount;
}
.slant-offset--bottom {
	padding-bottom: $slant-amount;
}

// Waves
$wave-height-min: 2rem;
$wave-height: 10vw;
$wave-height-max: 5rem;
.wave {
	top: 0;
	width: 100%;
	overflow: hidden;
	position: absolute;
	height: $wave-height-max;
	height: clamp(#{$wave-height-min}, #{$wave-height}, #{$wave-height-max});

	svg {
		width: 100%;
		height: $wave-height-max;
		height: clamp(#{$wave-height-min}, #{$wave-height}, #{$wave-height-max});
	}
}

.wave--flip {
	transform: scaleX(-1);
}

.has-top-wave {
	padding-top: $wave-height-max;
	padding-top: clamp(#{$wave-height-min}, #{$wave-height}, #{$wave-height-max});
}

.band--has-wave {
	position: relative;
}