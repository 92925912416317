// Image gallery on create/edit pages
.image-gallery {
	&.retain-scrollbar {
		overflow: auto;
		max-height: 300px;
	}

	img {
		border: .25rem solid transparent;
		border-radius: $border-radius-sm;
	}
}

// Stops bleeding when scrolling in modal
.modal-dialog-scrollable:has(.image-gallery) {
	.modal-body {
		padding-top: 0;
		margin-top: var(--modal-padding);
	}
}

img.selected,
.selected img {
	border: .25rem solid $pink;
}

// Image gallery prompt
.image-gallery-prompt {
	gap: .5rem;
	display: flex;
	overflow: hidden;
	text-align: center;
	position: relative;
	background: $navy-10;
	flex-direction: column;
	border-radius: $border-radius;
}
.image-gallery-prompt--images {
	width: 110%;
	gap: .5rem;
	margin: 0 -3%;
	display: grid;
	transform: rotate(-3deg);
	grid-template-columns: repeat(20, minmax(80px, 1fr));

	&:first-child {
		margin-top: -3%;
	}

	&:nth-of-type(2) {
		img {
			transform: translateX(-50%);
		}
	}
}
.image-gallery-prompt--content {
	padding: 1rem;
}