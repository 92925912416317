// nav-dropdown.scss

//
// Global
//

.dropdown-menu {
	display: none;
	color: $navy;
}

.dropdown-toggle {
	white-space: nowrap;

	&::after { // copied from Bootstrap but removed margin and added transition
		content: "";
		// margin-left: $caret-spacing;
		display: inline-block;
		vertical-align: $caret-vertical-align;
		@include caret-down();
		transition: transform .3s;
	}

	.dropdown.show &::after {
		transform: rotate(-180deg);
	}
}

// Reset positioning, means that dropdowns are left-aligned
#nav-menu li.dropdown {
	position: initial; 
}

// The dropdown
#nav-menu .dropdown-menu {
	left: 0;
	background-color: $nav-bg-dropdown;
}

.dropdown-header {
	color: $navy;
	font-weight: $font-weight-bold;
	margin: 0 0 $nav-dropdown-item-padding $nav-dropdown-item-padding;
}

.dropdown-item {
	color: $navy;
	display: flex;
	font-size: .875rem;
	text-decoration: none;
	padding: $nav-dropdown-item-padding;
	border-radius: $btn-border-radius;

	&:not(.dropdown-item--lg) {
		svg, img, .fa-layers {
			max-width: 2rem;
			margin-right: 1rem;
		}
	}

	.fa-layers {
		svg {
			margin: auto;
			max-width: 100%;
		}

		img {
			vertical-align: top;
		}

		.fa-layers {
			margin: 0;
		}
	}

	span:not(.badge):not(.fa-layers) {
		flex: 1;
	}

	&:hover, &:focus {
		color: $navy;
		text-decoration: none;
		background: $nav-bg-dropdown-hover;
	}
}

.dropdown-menu.show {
	display: block;
	z-index: $zindex-dropdown;
}

//
// Desktop Only
//

@include media-breakpoint-up(lg) { 
	// The dropdown
	#nav-menu .dropdown-menu {
    	left: 0;
    	bottom: 0;
    	width: 100vw;
    	position: absolute;
    	padding: $grid-gutter-width;
    	transform: translateY(calc(100% - 1px)); // Occasionally get bleed without the 1px
	}

	.dropdown-item--lg {
		b {
			display: block;
			font-size: 1.125rem;
		}

		svg, img, .fa-layers {
			margin-top: 0.25rem;
			margin-right: 1rem;
		}

		.fa-layers {
			text-align: left;

			img {
				position: absolute; // Make it like a FontAwesome icon
			}
		}

		.fa-lg {
			font-size: 1.75rem; // For FontAwesome
			max-width: 1.75rem; // For other SVGs
		}

		.fa-2x {
			font-size: 2rem; // For FontAwesome
			max-width: 2rem; // For other SVGs
		}

		.fa-3x {
			font-size: 3rem; // For FontAwesome
			max-width: 3rem; // For other SVGs
		}
	}

	.dropdown-menu--dividers > :not(:last-child) {
		border-right: 1px solid $nav-bg-dropdown-hover;

		&.col-lg-4:nth-child(3) {
			border: none;
		}
	}

	// Fade out rest of screen when desktop menu open
	body.dropdown-open {
		.nav-container + *:not(#cookie-banner) {
			position: relative;

			&:before {
				left: 0;
				content: '';
				opacity: 0.5;
				height: 100%;
				width: 100vw;
				display: block;
				position: absolute;
				background: $grey-90;
				z-index: $zindex-nav-backdrop;
			}
		}
	}
}

//
// Mobile Only
//

@include media-breakpoint-down(lg) {
	.dropdown-menu .container {
		padding: 0;
	}

	.nav-item { 
		margin-top: .5rem;
	}

	#nav-menu .dropdown-menu {
		border-radius: 0 0 $btn-border-radius $btn-border-radius;
		overflow: hidden;
	}

	.dropdown.show .nav-item {
		border-radius: $btn-border-radius $btn-border-radius 0 0;
		background-color: $nav-bg-dropdown-hover;
	}

	.dropdown-item {
		border-radius: 0;

		svg, img, .fa-layers, .fa-3x {
			font-size: 1em !important;
			max-width: 2rem !important;
			margin-right: 1rem;
		}

		.fa-layers:first-child:not(.fa-fw) {
			width: unset;
		}

		.fa-layers:first-child {
			margin-top: 0.25rem;
		}
	}

	// Stops weird capitalisation
	.dropdown-item--lg {
		b:only-child {
			font-weight: normal;
			text-transform: lowercase;
		}
	}

	// Remove the sub-headers in the dropdown, they just take up space on mobile
	.dropdown-header {
		display: none;
	}

	// Stop scrolling on mobile
	body.menu-open {
		overflow: hidden;
	}

	// Fade out rest of screen when mobile menu open
	.dropdown-backdrop.show {
		position: fixed;
		top: 0;
		left: 0;
		z-index: $zindex-nav-backdrop;
		width: 100vw;
		height: 100vh;
		background-color: $modal-backdrop-bg;
		opacity: $modal-backdrop-opacity;
	}
}