// Steps - e.g. how it works, list of features/benefits

.steps {
	height: 100%;
	display: flex;
	text-align: left;
	overflow: hidden;
	position: relative;
	flex-wrap: nowrap;
	padding-top: 1rem;
	text-wrap: balance;
	padding-bottom: 1rem;
	align-content: flex-start;

	&:not(.steps--inline) {
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;

			.steps__image {
				max-width: 100%;
			}
		}
	}
}

.steps-lg {
	--steps-size: 6rem;
}

.steps__image {
	--fa-block-width: clamp(1px, 100%, var(--steps-size, 5rem));
	flex-shrink: 0;
	width: 100%;
	max-width: var(--steps-size, 4rem);
	margin-right: var(--gutter-x);
	padding: var(--steps-padding-y, 0) 0;

	a {
		color: inherit; // Colour applies if it's a FontAwesome icon
	}

	a:hover > :first-child { // Can be <img> or a <span> containing FontAwesome SVGs
		opacity: 0.8;
		transition: $transition-base;
	}
}

.steps__text {
	flex-grow: 1;

	> * {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	> .btn {
		margin-top: 0.25rem;
	}

	@include media-breakpoint-down(md) {
		align-self: center;
	}
}

@include media-breakpoint-up(md) {
	// Extra padding for images
	.steps:not(.steps--inline) {
		--steps-padding-y: #{math.div($grid-gutter-width, 2)};
	}
}
