/**
 *  Hero Sections
 */

// 

.hero {
	overflow: hidden;
	position: relative;
	text-align: center;

	> .container, 
	> :not(.band__behind) > .row > * {
		z-index: 3;
		position: relative;
	}

	.row {
		align-items: center;
		justify-content: center;
	}

	@include media-breakpoint-up(md) {
		&:not(.text-center) {
			text-align: left;

			.row {
				justify-content: space-between;
			}
		}
	}

	// Add padding to columns
	.container > .row > * {
		--hero-content-padding: calc(var(--gutter-x) * 2);
		padding-bottom: var(--hero-content-padding);

		&:first-child {
			padding-top: var(--hero-content-padding);	
		}

		// Reduce padding on mobile
		& ~ * {
			@include media-breakpoint-up(md) {
				padding-top: var(--hero-content-padding);				
			}
		}

		// If we want image at bottom with no padding
		&:has(.hero__image--snap-bottom) {
			align-self: end;
			padding-bottom: 0;
		}
	}

	// Add padding for hero without row>col structure
	.container:not(:has(.row)) {
		--hero-content-padding: calc(var(--gutter-x) * 2);
		padding-top: var(--hero-content-padding);
		padding-bottom: var(--hero-content-padding);
	}

	h1 {
		margin-bottom: 0;

		& ~ *:not(.btn-set) {
			margin-top: $headings-margin-bottom;
			margin-bottom: 0;
		}

		& ~ .btn,
		& ~ .btn-set {
			margin-top: $headings-margin-bottom * 2;
		}
	}

	p {
		text-wrap: balance;
	}
}

// Backgrounds and fades

.hero--fade::before, .hero--fade--left::before {
	top: 0;
	left: 0;
	z-index: 2;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	pointer-events: none;
	background: rgba($grey, .7);
}

@include media-breakpoint-up(md) {
	.hero--fade--left::before {
		background: linear-gradient(to right, rgba($grey, .8), rgba($grey, .5), transparent);
	}
}

.hero__bg {
	left: 0;
	width: 100%;
	min-height: 100%;
	object-fit: cover;
	position: absolute;
	pointer-events: none;
}

.hero__bg--center {
	top: 0;
	bottom: 0;
	height: 0;
}

// Hero images (not backgrounds)

.hero__image {
	margin-left: auto; 
	margin-right: auto;
	max-height: 300px;
}

@include media-breakpoint-up(sm) {
	.hero__image {
		max-height: 100%;
	}
}