/*
 * BOXES - different types of them
 * 
 * box - standard box
 * 
 * list-box - a list of stuff, striped
 * 
 */

// Variables
// Set in variables.scss

// Parent stuff to include flex 

.boxes, 
.boxes > div {
	display: flex;
	flex-wrap: wrap;
}

.boxes {
	&.mb-0 {
		margin-bottom: -1rem !important;
	}
	
	.box {
		display: flex;
		flex-direction: column;

		.row {
			height: 100%;
		}
	}

	.box__content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	+ nav > .pagination {
		padding-top: 0 !important;
	}
}

.box--border.box,
.boxes--borders .box {
	border: 1px solid $grey-20;
}

// Base box
.box {
	--text-color-rgb: var(--body-color-rgb);
	--link-color: #{$link-color};
	--link-hover-color: #{$link-hover-color};

	// z-index: 3; // Causes issues with visibility of images for some reason, can't remember it's here in the first place
	width: 100%;
	overflow: hidden; // Needed for list-box border-radius stuff but might as well but here
	position: relative; // Needed for account/profile page
	margin-bottom: var(--box-margin, $grid-gutter-width);
	box-shadow: $box-shadow; 
	transition: $transition-base;
	background: var(--box-bg, #fff);
	color: var(--box-color, $text-colour);
	border-radius: var(--box-border-radius, $border-radius-sm);

	// Add spacing for all siblings (of any type - saves having to specify different types, e.g. other boxes, banners etc)
	& ~ :not(nav) {
		margin-top: $grid-gutter-width;

		&:last-child {
			margin-bottom: $grid-gutter-width;			
		}
	}

	address {
		margin: 0;
	}
}

.box-lg {
	--box-padding-x: clamp(1.5rem, 6vw, 4.5rem);
	--box-padding-y: clamp(1.5rem, 6vw, 4.5rem);
}

// Sometimes we use .alert.alert-* to set the bg+colour, this unsets some styles
.box.alert,
.box__content.alert,
.box > .alert:only-child {
	--alert-border: none;
	--alert-border-radius: none;
	--alert-margin-bottom: 0;
}

// Content
.box__content {	
	position: relative;
	padding: var(--box-padding-y, $box-padding-y) var(--box-padding-x, $box-padding-x);

	> :last-child {
		margin-bottom: 0;
	}

	> .steps {
		padding-top: 0;
		padding-bottom: 0;
	}

	> nav > .pagination {
		padding: 0 !important;
		margin: 0 !important;
	}
}

.box__bottom,
.boxes .box__content .btn {
	margin-top: auto;
}

// Tighter content
.box--tight {
	--box-padding-y: 1rem;
}

.box--nav .box__content {
	--box-padding-y: .5rem;
}

.box--nav__sub {
	padding-left: calc(var(--box-padding-x, $box-padding-x) * 2);
}

// Main image (usually a logo)
.box__image {
	@extend .logo !optional;
	@include media-breakpoint-down(md) {
		margin-bottom: 1rem;
	}
}

.box__image--horizontal {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

// General images
.box a {
	img {
		transition: opacity .3s ease-in-out;
		// -webkit-backface-visibility: hidden; // fixes weird size change (chrome bug) // If you see it again, paste the link in here!
	}

	&:hover, &:focus {
		img:not(:first-of-type:not(:last-of-type)) {
			opacity: 0.8;
		}
	}
}

/*
 * list-box, useful for lists of stores/offers/stuff
 */

// Use .list-box with a .bg-* class (typically bg-grey-5 or bg-blue-10) to apply striped elements
.list-box:not([class*="bg-"]),
.list-box:not([class*="bg-"]) .sticky-header { // Urgh
	background: var(--box-stripe-bg, $grey-5);
}
.list-box:not(.list-box--staggered):not(table) > :nth-of-type(odd),
.list-box:not(.list-box--staggered):not(table) > :nth-of-type(odd) .sticky-header, // Urgh
table.list-box:not(.list-box--staggered) tr:nth-of-type(odd) {
	background: var(--box-bg, #fff);
}

// This changes the order of the stripes (color/white/colour/white/etc instead of white/colour/white/colour/etc)
.list-box--staggered > :nth-of-type(even),
.list-box--staggered > :nth-of-type(even) .sticky-header, // Urgh
.list-box--staggered tr:nth-of-type(even) {
	background: var(--box-bg, #fff);
}

.list-box > :last-child:not(.modal-body) {
	margin-bottom: -1px; // Don't know why
}

// Pagination
.box nav {
	overflow: auto; // Sorts out child's margin-bottom not affecting background colour due to inherited overflow:hidden;
}
.list-box--pagination {
	text-align: center;

	ul {
		margin: .5rem 0;
		padding: 0;
	}
}

.box + nav .pagination {
	padding-top: 0 !important;
}

// Navigation
.list-box__nav {
	margin: 0;

	li {
		display: block;
		font-size: .875rem;
		padding: .5rem 1rem;
		transition: background 0.25s;
		i {
			color: $grey;
		}
	}
	li:nth-child(even) {
		background: $grey-5;
	}
	li {
		&:hover, &:focus {
			background: $navy-25;
			a, i {
				color: $navy;
				text-decoration: none;
			}
		}
	}
	li.active {
		color: #fff;
		background: $navy;
		a, i {
			color: #fff;
		}
	}
	li[role="button"] {
		cursor: pointer;
	}
}
