// Various helper classes
// In some cases, you'll instead want to add to overrides-utilities.scss so you can leverage Bootstrap's utility builder

// Simple hide class
.hide {
	display: none !important;
}

// Make greyscale
.greyscale, 
.greyscale img {
	filter: grayscale(100%);
}

// General style to apply to images / logos
.logo,
.img-rounded {
	border-radius: var(--img-border-radius, $border-radius-sm);
}

.logo ,
.img-border {
	border: 1px solid $grey-10;
}

.logo,
.img-shadow {
	box-shadow: $box-shadow;
}

// Add shadow to image - works on PNGs
.img-shadow-filter {
	filter: drop-shadow($box-shadow-lg);
}

.img-sm {
	&.logo,
	&.img-rounded {
		border-radius: var(--img-border-radius-sm, $border-radius-xs);
	}
	&.logo,
	&.img-shadow {
		box-shadow: $box-shadow-sm;
	}
}

.img-fit {
	width: 100%;
	height: auto;
}

.img-emboss {
	padding: 1px;
	background: #fff;
}

.img-inset {
	top: 50%;
	z-index: 2;
	width: auto;
	left: 1.5rem;
	position: absolute;
	max-width: calc(100% - 3rem);
	max-height: calc(100% - 3rem);
	transform: translateY(-50%);
}

// Image bursts out of disc, e.g. /app page
.img-bursting {
	border-radius: 0 0 50% 50%;
	border-bottom: 3px solid $grey-10;
	filter: drop-shadow($box-shadow-lg);
}

// Make on-white images merge with background colour
.img-thru {
	mix-blend-mode: darken;
}

// 
.img-burn {
	mix-blend-mode: color-burn;
}

// Fade image on hover
.img-hover {
	transition: $transition-base;

	&:hover {
		opacity: 0.8;
	}
}

// Scale image on hover
.img-scale {
	transition: $transition-base;

	&:hover {
		transform: scale(1.1);
	}
}

// Crop image to col-* height, add class to col-*
.img-crop {
	height: 100%;
	overflow: hidden;
	position: relative;

	img {
		position: absolute;
	}
}

// Flip an image (or other element) horizontally
.flip-x {
	transform: scaleX(-1);
}

// Remove shadow
.shadow-0 {
	box-shadow: none !important;
}

@include media-breakpoint-down(md) {
	.container.p-0 > .row {
		margin-left: 0;
		margin-right: 0;

		& > [class*=col-] {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

// Custom background
.bg-custom {
	background: var(--customBackground) !important;
}

.bg-custom-gradient-y {
	background: linear-gradient(to bottom, var(--customBackgroundTop) 0%, var(--customBackgroundBottom) 100%) !important;
}

// Custom text
.text-custom {
	color: var(--customText) !important;
}

// Adds monospacing to green text which is usually used for commission rates, makes numbers line up nicer
.text-green,
.text-numeric {
	font-variant-numeric: tabular-nums;
}

// Example: .text-center.mw-60ch won't be centered within its parent
.text-center[class*=mw-],
.text-center [class*=mw-] {
	margin-left: auto;
	margin-right: auto;
}

.text-left {
    text-align: left;
}

.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// Custom border
.border-custom {
	border-color: var(--customBorder, var(--customText)) !important;
}

// Font-Awesome override - make it like an image so 100% of container (was called .fa-block but caused problems with FontAwesome's JavaScript)
.icon-block {
	width: var(--fa-block-width, 100%);
	padding-top: var(--fa-block-width, 100%);
	
	svg {
		width: 100% !important;
		height: auto !important;
		max-height: 100% !important;
	}
}
.icon-shift-left {
	margin-left:  -.125em;
}

.icon-outline {
	svg path {
		stroke: currentColor;
		stroke-width: 10%;
		stroke-linecap: round;
		fill: none;
	}
}

.dual-icons {
	> * {
		--fa-block-width: 50%;

		width: 50%;
		position: relative;
		display: inline-block;

		&:first-child {
			z-index: 3;
			margin-right: -10%;
		}
	}
}

// Font-Awesome override - stops CLS (content layout shift) by applying width to the initial empty <i class="fa fa-fw etc"></i> tag
i:empty {
	width: var(--fa-fw-width, 1.25em);

	// This only applies to single icons, i.e. excludes stacked icons using .fa-layers
	&:only-of-type {
		display: inline-block;
	}
}

// Font-Awesome override - stops analytics thinking the icon was clicked rather than the parent button
.btn i,
.btn svg {
	pointer-events: none;
}

// Star - used for favourites and reviews
.star {
	color: $gold;
}

// Little override to make badge elements (look like pills) display a little nicer
span.badge {
	vertical-align: text-bottom;
}

// Partial collapse - great for paragraphs
.partial-collapse:not(.show) {
	height: 10rem; // fallback for clamp
	display: block;
	overflow: hidden;
	position: relative;
	mix-blend-mode: darken; // This is make it work on non-white backgrounds
	height: clamp(5rem, 50vh, 30rem);

	.modal & {
		height: clamp(5rem, 25vh, 15rem);
	}

	&:after {
		left: 0;
		bottom: 0;
		content: '';
		width: 100%;
		height: 50%;
		display: block;
		position: absolute;
		height: clamp(1rem, 50%, 10rem);
		background: linear-gradient(to top, #fff 0%, rgba(255,255,255,.1) 100%); // Using "transparent" causes greyness on Safari and some browsers, so we use almost-transparent white instead
	}
}

.retain-scrollbar {
	overflow-x: hidden;
	overflow-y: scroll;
	
	&::-webkit-scrollbar {
		width: 7px;
		-webkit-appearance: none;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0,0,0,.5);
		-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
	}
}

// Make header sticky
.sticky-header {
	top: 0;
	position: sticky;
	background: #fff;
	z-index: 10; // Don't use $zindex-sticky as too high
}


// Make header sticky
.sticky-error {
	top: 0;
	position: sticky;
	z-index: $zindex-sticky;
}


.sticky-nav {
	top: 1rem;
	position: sticky;
}

// On lite templates, push footer to bottom if page content is less than the window height (apply to <body>)
.has-lite-footer {
	min-height: 100vh;

    &, & > main {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}

// Fix for inline-block elements within a link not getting underlined
a:hover .d-inline-block {
	text-decoration: inherit;
}

// Simple shake anim
.shake {
	animation: shake 1.5s ease-in-out infinite;
}
@keyframes shake {
	25%, 75% { transform: rotate(0deg); } 
	50% { transform: rotate(-5deg); }
}

.mini-pagination .pagination-label {
	display: none;
}

// Pagination
li.page-item {
	white-space: nowrap;
}

// Blobs
.bg-blob-blur {
	top: 0;
	opacity: 0.15;
	background: $navy;
	position: absolute;
	filter: blur(100px);
	left: clamp(1px, 5vw, 30%);
	width: clamp(1px, 30vw, 100%);
	height: clamp(1px, 30vh, 100%);
}
.bg-blob--right {
	left: unset;
	right: clamp(1px, 5vw, 30%);
}

// Lists - improve numbering of ordered lists
ol {
	counter-reset: item;
	& > li {
		list-style: none;
		&:before {
			content: counters(item, ".") ". ";
			counter-increment: item;
			font-weight: bold;
			position: absolute;
			transform: translateX(calc(-100% - 0.5rem));
		}
	}
}

// Zendesk override
#launcher {
	transition-property: opacity !important; // Zendesk uses `opacity, top, bottom` - the latter two are bad for performance
}