//
// Topbar / preheader / prenav
//

.topbar {
	text-align: right;

	.btn {
		font-size: .75rem;
		text-transform: uppercase;
		border-radius: 0;
	}

	.btn-silver + .btn-silver {
		border-left: 1px solid $grey-20;
	}
}